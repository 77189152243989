import { Injectable } from '@angular/core';
import { IContactCommon, IEtatCommon } from '../models/common';
import { IMonitoringOut, IFluxCompletOut, IDemiFluxOut, IInstanceMonitoringOut, ILastEtatMonitoringOut } from '../models/out';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { IMonitoring } from 'src/app/shared/models/monitoring';
import { IContact } from 'src/app/shared/models/contact';
import { IHalfFlow } from 'src/app/shared/models/half-flow';
import { IInstanceMonitoring } from 'src/app/shared/models/instance-monitoring';
import { IState } from 'src/app/shared/models/state';
import { ILastMonitoringState } from 'src/app/shared/models/last-monitoring-state';
import { IEtatInstanceFluxComplet } from 'src/app/shared/models/etat-instance-flux-comple';
import { IEtatInstanceFluxCompletOut } from '../etat-instance-flux-complet.service';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() { }

  mapInstanceMonitoring(instanceMonitoring: IInstanceMonitoringOut): IInstanceMonitoring {
    return {
      id: instanceMonitoring.idInstanceMonitoring,
      uuidInstanceMonitoring: instanceMonitoring.uuidInstanceMonitoring,
      creationDate: instanceMonitoring.dateCreation
    };
  }

  mapHalfFlow(demiFlux: IDemiFluxOut): IHalfFlow {
    return {
      id: demiFlux.idDemiFlux,
      serviceName: demiFlux.nomService,
      application: {
        id: demiFlux.application.idApplication,
        name: demiFlux.application.nom
      },
      completeFlow: this.mapCompleteFlow(demiFlux.fluxComplet)
    };
  }

  mapContact(contact: IContactCommon): IContact {
    return {
      id: contact.idContact,
      ldap: {
        login: contact.loginLdap,
        groupe: contact.groupeLdap,
        domaine: contact.domaineLdap
      },
      email: contact.email,
      isEmailActive: contact.emailActif,
      langage: contact.langage
    };
  }

  mapCompleteFlow(fluxComplet: IFluxCompletOut): ICompleteFlow {
    return {
      id: fluxComplet.idFluxComplet,
      name: fluxComplet.nom,
      isActive: fluxComplet.estActif,
      businessObject: fluxComplet.businessObject,
      purgeDelay: fluxComplet.delaiDePurge,
      domaine: {
        id: fluxComplet.domaine.idDomaine,
        name: fluxComplet.domaine.nom
      }
    };
  }

  mapLastMonitoringState(lastEtatMonitoring: ILastEtatMonitoringOut): ILastMonitoringState {
    return {
      monitoringStateId: lastEtatMonitoring.idEtatMonitoring,
      state: this.mapState({
        idEtat: lastEtatMonitoring.idEtat,
        nom: lastEtatMonitoring.nomEtat,
        niveauDeCriticite: lastEtatMonitoring.niveauDeCriticiteEtat,
      }),
      error: lastEtatMonitoring.erreur,
      stackTrace: lastEtatMonitoring.stackTrace,
      creationDate: lastEtatMonitoring.dateCreation
    };
  }

  mapMonitoring(monitoring: IMonitoringOut): IMonitoring {
    return {
      id: monitoring.idMonitoring,
      uuidMonitoring: monitoring.uuidMonitoring,
      uuidParent: monitoring.uuidParent,
      creationDate: monitoring.dateCreation,
      instanceMonitoring: this.mapInstanceMonitoring(monitoring.instanceMonitoring),
      halfFlow: this.mapHalfFlow(monitoring.demiFlux),
      lastMonitoringState: this.mapLastMonitoringState(monitoring.lastEtatMonitoring)
    };
  }

  mapEtatInstanceFluxComplet(etatInstance: IEtatInstanceFluxCompletOut): IEtatInstanceFluxComplet {
    return {
      state: this.mapState({
        idEtat: etatInstance.etat.idEtat,
        nom: etatInstance.etat.nom,
        niveauDeCriticite: etatInstance.etat.niveauDeCriticite,
      }),
      fluxComplet: this.mapCompleteFlow(etatInstance.fluxComplet),
      instanceMonitoring: this.mapInstanceMonitoring(etatInstance.instanceMonitoring),
    };
  }

  mapState(etat: IEtatCommon): IState {
    let label = '';
    let color = '';
    let icon = '';
    switch (etat.nom) {
      case 'DEMARRE':
        label = 'Démarré';
        color = 'blue';
        icon = 'play_arrow';
        break;
      case 'ERREUR':
        label = 'Erreur';
        color = 'red';
        icon = 'error_outline';
        break;
      case 'RESOUMIS':
        label = 'Resoumis';
        color = 'purple';
        icon = 'cached';
        break;
      case 'SUCCES':
        label = 'Succès';
        color = 'green';
        icon = 'check_circle_outline';
        break;

      default:
        break;
    }
    return {
      id: etat.idEtat,
      name: etat.nom,
      label,
      color,
      icon,
      niveauDeCriticite: etat.niveauDeCriticite
    };
  }
}
