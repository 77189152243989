import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IInstanceMonitoringIn, IEtatInstanceFluxCompletListIn } from './models/in';

export interface IInstanceMonitoringCountServiceInputs {
  instanceMonitoring: IInstanceMonitoringIn;
  etatInstanceFluxCompletList: IEtatInstanceFluxCompletListIn;
}
interface IOutputs {
  count: string;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceMonitoringCountService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getInstanceMonitoringCount(inputs: IInstanceMonitoringCountServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/instanceMonitoringCount';
    return this.http.post(url, inputs).toPromise().then((outputs: IOutputs) => {
      return outputs.count;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
