import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { FlowTrackingRoutingModule } from './flow-tracking-routing.module';

import { FlowTrackingComponent } from './flow-tracking/flow-tracking.component';
import { FlowTrackingFilterBarComponent } from './flow-tracking-filter-bar/flow-tracking-filter-bar.component';
import { FlowTrackingFlowsTabComponent } from './flow-tracking-flows-tab/flow-tracking-flows-tab.component';
import { FlowTrackingResubmitDialogComponent } from './flow-tracking-resubmit-dialog/flow-tracking-resubmit-dialog.component';



@NgModule({
  declarations: [
    FlowTrackingComponent,
    FlowTrackingFilterBarComponent,
    FlowTrackingFlowsTabComponent,
    FlowTrackingResubmitDialogComponent
  ],
  imports: [
    SharedModule,
    FlowTrackingRoutingModule
  ]
})
export class FlowTrackingModule { }
