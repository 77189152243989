import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { IMonitoringStateCountServiceInputs, MonitoringStateCountService } from 'src/app/services/API/cockpit/monitoring-state-count.service';
import { IMonitoringCountServiceInputs, MonitoringCountService } from 'src/app/services/API/cockpit/monitoring-count.service';
import { IPagination } from 'src/app/shared/models/pagination';
import { IEtatInstanceFluxCompletCountServiceInputs, EtatInstanceFluxCompletCountService } from 'src/app/services/API/cockpit/etat-instance-flux-complet-count.service';
import { Sort } from '@angular/material/sort';
import { IState } from 'src/app/shared/models/state';

interface IFlowsInfos {
  id: string;
  businessObject: string;
  name: string;
  totalFlux: string;
  totalFluxErrors: string;
  totalFluxSuccess: string;
  totalDemiFlux: string;
  totalDemiFluxErrors: string;
  totalDemiFluxSuccess: string;
  totalDemiFluxResoumis: string;
}

@Component({
  selector: 'app-dashboard-flows-tab',
  templateUrl: './dashboard-flows-tab.component.html',
  styleUrls: ['./dashboard-flows-tab.component.scss']
})
export class DashboardFlowsTabComponent implements OnInit, OnChanges {
  @Input() completeFlows: ICompleteFlow[];
  @Input() states: IState[];
  flowsInfos: IFlowsInfos[];
  displayedColumns: string[] = ['businessObject', 'nomFlux', 'totalFlux', 'totalFluxErreur', 'totalFluxSucces', 'totalDemiFlux', 'totalDemiFluxErreur', 'totalDemiFluxSucces', 'totalDemiFluxResoumis'];
  dataSource: MatTableDataSource<IFlowsInfos>;
  pagination: IPagination;
  sort: Sort = {
    active: 'nomFlux',
    direction: 'asc'
  };
  constructor(
    private router: Router,
    private instanceMonitoringCountService: MonitoringCountService,
    private monitoringStateCountService: MonitoringStateCountService,
    private etatInstanceFluxCompletCountService: EtatInstanceFluxCompletCountService
  ) {
  }

  ngOnInit() {
    this.setPagination('10');
    this.tabUpdate();
  }
  ngOnChanges() {
    if (this.completeFlows) {
      this.flowsInfos = [];
      if (this.completeFlows.length !== 0) {
        this.completeFlows.map(async completeFlow => {
          const flowInfos: IFlowsInfos = {
            id: completeFlow.id,
            businessObject: completeFlow.businessObject,
            name: completeFlow.name,
            totalFlux: await this.getEtatInstanceFluxCompletCount(
              {
                fluxComplet: {
                  idFluxComplet: completeFlow.id
                }
              } as IEtatInstanceFluxCompletCountServiceInputs
            ).catch(() => '?'),
            totalFluxSuccess: await this.getEtatInstanceFluxCompletCount(
              {
                fluxComplet: {
                  idFluxComplet: completeFlow.id
                },
                etat: {
                  nom: 'SUCCES'
                }
              } as IEtatInstanceFluxCompletCountServiceInputs
            ).catch(() => '?'),
            totalFluxErrors: await this.getEtatInstanceFluxCompletCount(
              {
                fluxComplet: {
                  idFluxComplet: completeFlow.id
                },
                etat: {
                  nom: 'ERREUR'
                }
              } as IEtatInstanceFluxCompletCountServiceInputs
            ).catch(() => '?'),
            totalDemiFlux: await this.getMonitoringCount(
              {
                fluxComplet: {
                  idFluxComplet: completeFlow.id
                }
              } as IMonitoringCountServiceInputs
            ).catch(() => '?'),
            totalDemiFluxErrors: await this.getMonitoringCount({
              lastEtatMonitoring: {
                nomEtat: 'ERREUR'
              },
              fluxComplet: {
                idFluxComplet: completeFlow.id
              }
            } as IMonitoringCountServiceInputs).catch(() => '?'),
            totalDemiFluxSuccess: await this.getMonitoringCount({
              lastEtatMonitoring: {
                nomEtat: 'SUCCES'
              },
              fluxComplet: {
                idFluxComplet: completeFlow.id
              }
            } as IMonitoringCountServiceInputs).catch(() => '?'),
            totalDemiFluxResoumis: await this.getMonitoringCount({
              lastEtatMonitoring: {
                nomEtat: 'RESOUMIS'
              },
              fluxComplet: {
                idFluxComplet: completeFlow.id
              }
            } as IMonitoringCountServiceInputs).catch(() => '?')
          };
          this.flowsInfos.push(flowInfos);
          if (this.completeFlows.length === this.flowsInfos.length) {
            this.tabUpdate();
          }
        });
      } else {
        this.tabUpdate();
      }
    }
  }

  public tabUpdate(dontSort?: boolean) {
    if (this.flowsInfos) {
      if (!dontSort) {
        this.sortData(this.sort);
      }
      this.updatePaginationCount();
      this.dataSource = new MatTableDataSource<IFlowsInfos>(this.flowsInfos);
      const firstRow = Number(this.pagination.firstRow) - 1;
      const lastRow = Number(this.pagination.lastRow);
      const dataToShow = this.dataSource.data.slice(firstRow, lastRow);
      this.dataSource = new MatTableDataSource<IFlowsInfos>(dataToShow);
    }
  }

  async getMonitoringCount(inputs: IMonitoringCountServiceInputs) {
    return await this.instanceMonitoringCountService.getMonitoringCount(inputs).then((result) => {
      return result;
    });
  }

  async getMonitoringStateCount(inputs: IMonitoringStateCountServiceInputs) {
    return await this.monitoringStateCountService.getMonitoringStateCount(inputs).then((result) => {
      return result;
    });
  }

  async getEtatInstanceFluxCompletCount(inputs: IEtatInstanceFluxCompletCountServiceInputs) {
    return await this.etatInstanceFluxCompletCountService.getEtatInstanceFluxCompletCount(inputs).then((result) => {
      return result;
    });
  }

  onGoFlowTrackingWithId(flowInfos: IFlowsInfos) {
    this.router.navigate(['/flow-tracking'], { queryParams: { id: flowInfos.id } });
  }

  onGoFlowTrackingWithState(flowInfos: IFlowsInfos, stateName: string) {
    const state = this.states.find(aState => aState.name === stateName).id;
    this.router.navigate(['/flow-tracking'], {
      queryParams: {
        id: flowInfos.id,
        state
      }
    });
  }

  onGoNext() {
    if (+this.pagination.currentPage < +this.pagination.totalPage) {
      this.pagination.currentPage = Math.floor(+this.pagination.currentPage + 1).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow + +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow + +this.pagination.taillePage).toString();
      this.tabUpdate(true);
    }
  }

  onGoPrevious() {
    if (+this.pagination.currentPage > 1) {
      this.pagination.currentPage = Math.floor(this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow - +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow - +this.pagination.taillePage).toString();
      this.tabUpdate(true);
    }
  }

  onGoFirst() {
    this.pagination.currentPage = '1';
    this.updatePaginationRows();
    this.tabUpdate(true);
  }

  onGoPage(page: string) {
    this.pagination.currentPage = page ? page : '1';
    this.updatePaginationRows();
    this.tabUpdate(true);
  }

  onGoLast() {
    this.pagination.currentPage = this.pagination.totalPage;
    this.updatePaginationRows();
    this.tabUpdate(true);
  }


  setPagination(totalPage: string) {
    this.pagination = {
      currentPage: '1',
      totalPage: '1',
      taillePage: totalPage,
      firstRow: '1',
      lastRow: Math.floor(+totalPage).toString(),
      totalRows: '0'
    };
  }
  async updatePaginationCount() {
    if (this.flowsInfos && this.pagination) {
      this.pagination.totalRows = this.flowsInfos.length.toString();
      this.pagination.totalPage = Math.ceil(+this.flowsInfos.length.toString() / +this.pagination.taillePage).toString();
      if (+this.pagination.currentPage > +this.pagination.totalPage) {
        this.pagination.currentPage = this.pagination.totalPage;
      }
      if (this.pagination.totalPage > '0' && this.pagination.currentPage === '0') {
        this.pagination.currentPage = '1';
      }
      this.updatePaginationRows();
    }
  }

  updatePaginationRows() {
    if (this.pagination) {
      this.pagination.firstRow = Math.floor(
        (this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0) * +this.pagination.taillePage + 1
      ).toString();
      this.pagination.lastRow = Math.floor((+this.pagination.currentPage) * +this.pagination.taillePage).toString();
      if (+this.pagination.lastRow > +this.flowsInfos.length.toString()) {
        this.pagination.lastRow = this.flowsInfos.length.toString();
      }
    }
  }

  paginationTaillePageChanged() {
    this.pagination.currentPage = '1';
    this.updatePaginationCount().then(() => this.tabUpdate(true));
  }

  sortData(sort: Sort) {
    this.sort = sort;
    this.flowsInfos = this.flowsInfos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'businessObject': return this.compare(a.businessObject, b.businessObject, isAsc);
        case 'nomFlux': return this.compare(a.name, b.name, !isAsc); // bug tri nom ?
        case 'totalFlux': return this.compare(Number(a.totalFlux), Number(b.totalFlux), isAsc);
        case 'totalFluxErreur': return this.compare(Number(a.totalFluxErrors), Number(b.totalFluxErrors), isAsc);
        case 'totalFluxSucces': return this.compare(Number(a.totalFluxSuccess), Number(b.totalFluxSuccess), isAsc);
        case 'totalDemiFlux': return this.compare(Number(a.totalDemiFlux), Number(b.totalDemiFlux), isAsc);
        case 'totalDemiFluxErreur': return this.compare(Number(a.totalDemiFluxErrors), Number(b.totalDemiFluxErrors), isAsc);
        case 'totalDemiFluxSucces': return this.compare(Number(a.totalDemiFluxSuccess), Number(b.totalDemiFluxSuccess), isAsc);
        case 'totalDemiFluxResoumis': return this.compare(Number(a.totalDemiFluxResoumis), Number(b.totalDemiFluxResoumis), isAsc);

        default: return 0;
      }
    });
    this.onGoFirst();
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
