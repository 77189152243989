import { IMonitoring } from './../../../shared/models/monitoring';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-flow-tracking-resubmit-dialog',
  templateUrl: './flow-tracking-resubmit-dialog.component.html',
  styleUrls: ['./flow-tracking-resubmit-dialog.component.scss']
})
export class FlowTrackingResubmitDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
  }

}
