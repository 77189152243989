import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IState } from 'src/app/shared/models/state';
import { IEtatCommon } from './models/common';
import { IOrderBy } from './models/in';
import { MappingService } from './utils/mapping.service';

export interface IStateServiceInputs {
  etat: IEtatCommon;
  orderBy: IOrderBy[];
}
interface IOutputs {
  etatList: IEtatCommon[];
  error: {
    errorDetail: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getStateList(inputs: IStateServiceInputs): Promise<IState[]> {
    const url = this.baseUrl + '/apiCockpit/etat';
    const stateList: IState[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.etatList.map((output: IEtatCommon) => {
          const state: IState = this.mapping.mapState(output);
          stateList.push(state);
        });
      })
    ).toPromise().then(() => {
      return stateList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
