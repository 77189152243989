<nav fxLayout="row" class="mat-elevation-z5">
  <img class="navbar-logo" src="./assets/img/logo.png">
  <button *ngIf="isAuthenticated" mat-button [routerLink]="['/dashboard']" class="menu-button"
    routerLinkActive="active-link">
    <mat-icon>dashboard</mat-icon> Tableau de bord
  </button>
  <button *ngIf="isAuthenticated" mat-button [routerLink]="['/flow-tracking']" class="menu-button"
    routerLinkActive="active-link">
    <mat-icon>track_changes</mat-icon> Suivi de flux
  </button>
  <button *ngIf="isAuthenticated" mat-button [routerLink]="['/monitoring']" class="menu-button"
    routerLinkActive="active-link">
    <mat-icon>account_tree</mat-icon> Monitoring
  </button>
  <button *ngIf="isAuthenticated" mat-button (click)="logout()" class="menu-button"
    style="background-color: #E5004A; margin-left: auto; color: white;" routerLinkActive="active-link">
    <mat-icon>power_settings_new</mat-icon> Logout
  </button>
</nav>
