import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { IBusinessDataMonitoring } from 'src/app/shared/models/business-data-monitoring';
import { BusinessDataMonitoringService, IBusinessDataMonitoringServiceInputs } from 'src/app/services/API/cockpit/business-data-monitoring.service';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-monitoring-dialog',
  templateUrl: './monitoring-dialog.component.html',
  styleUrls: ['./monitoring-dialog.component.scss']
})
export class MonitoringDialogComponent implements OnInit {
  businessDataMonitoring: IBusinessDataMonitoring[];
  displayedColumns: string[] = ['cle', 'valeur'];
  dataSource: MatTableDataSource<IBusinessDataMonitoring>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<MonitoringDialogComponent>,
    private loaderService: LoaderService,
    private businessDataMonitoringService: BusinessDataMonitoringService
  ) { }

  ngOnInit(): void {
    this.getBusinessDataMonitoringList({
      monitoring: {
        uuidMonitoring: this.data
      },
      orderBy: [
        {
          direction: 'ASC',
          field: 'businessDataMonitoring.cle'
        }
      ],
    } as IBusinessDataMonitoringServiceInputs).then(() => {
      this.dataSource = new MatTableDataSource<IBusinessDataMonitoring>(this.businessDataMonitoring);
    });
  }

  async getBusinessDataMonitoringList(inputs: IBusinessDataMonitoringServiceInputs) {
    const loadindElement = this.loaderService.addLoadingElement();
    return this.businessDataMonitoringService.getBusinessDataMonitoringList(inputs).then(results => {
      this.businessDataMonitoring = results;
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

  sortData(sort: Sort) {
    this.businessDataMonitoring = this.businessDataMonitoring.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'cle': return this.compare(a.key, b.key, isAsc);
        case 'valeur': return this.compare(a.value, b.value, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<IBusinessDataMonitoring>(this.businessDataMonitoring);
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
