import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { CompleteFlowService, ICompleteFlowServiceInputs } from 'src/app/services/API/cockpit/complete-flow.service';
import { ISearch } from '../flow-tracking-filter-bar/flow-tracking-filter-bar.component';

@Component({
  selector: 'app-flow-tracking',
  templateUrl: './flow-tracking.component.html',
  styleUrls: ['./flow-tracking.component.scss']
})
export class FlowTrackingComponent implements OnInit {
  id: string;
  state: string;
  completeFlows: ICompleteFlow[];
  search: ISearch;
  completFlowDone = false;
  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private completeFlowServices: CompleteFlowService
  ) {
    this.search = {
      completeFlowId: null,
      stateId: null,
      applicationId: null,
      startDate: null,
      endDate: null,
      businessDatas: []
    };
  }

  ngOnInit(): void {
    this.getCompleFlowList({
      fluxComplet: {
        estActif: 'true',
      },
      orderBy: [{
        field: 'fluxComplet.nom',
        direction: 'asc'
      }],
    } as ICompleteFlowServiceInputs);
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
      this.state = params.state;
      if (this.id) {
        this.search.completeFlowId = this.id;
      }
      if (this.state) {
        this.search.stateId = this.state;
      }
    });
  }

  onSearchEvent(search: ISearch) {
    this.search = {
      completeFlowId: search.completeFlowId,
      stateId: search.stateId,
      applicationId: search.applicationId,
      startDate: search.startDate,
      endDate: search.endDate,
      businessDatas: search.businessDatas
    };
  }

  onResetEvent() {
    this.search = null;
  }

  getCompleFlowList(inputs: ICompleteFlowServiceInputs) {
    const loadindElement = this.loaderService.addLoadingElement();
    this.completeFlowServices.getCompleteFlowList(inputs).then((results: ICompleteFlow[]) => {
      this.completeFlows = results;
      this.completFlowDone = true;
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

}
