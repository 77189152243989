<div *ngIf="data.message">
  <p>{{ data.message }}</p>
</div>
<div *ngIf="!data.message">
  <p *ngIf="data.monitorings.length === 1">
    {{ data.monitorings.length }} élément a été rejoué avec succés.
  </p>
  <p *ngIf="data.monitorings.length > 1">
    {{ data.monitorings.length }} éléments ont été rejoués avec succés.
  </p>
</div>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>
