import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  IEtatMonitoringIn,
  IMonitoringIn,
  IInstanceMonitoringIn,
  IDemiFluxIn,
  IFluxCompletIn,
  IPagination,
  IOrderBy,
  ILastEtatMonitoringIn
} from './models/in';
import { IEtatCommon, IApplicationCommon, IDomaineCommon } from './models/common';
import { IEtatMonitoringOut } from './models/out';
import { IMonitoringState } from 'src/app/shared/models/monitoring-state';
import { MappingService } from './utils/mapping.service';

export interface IMonitoringStateServiceInputs {
  etatMonitoring: IEtatMonitoringIn;
  etat: IEtatCommon;
  monitoring: IMonitoringIn;
  instanceMonitoring: IInstanceMonitoringIn;
  lastEtatMonitoring: ILastEtatMonitoringIn;
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  pagination: IPagination;
  orderBy: IOrderBy[];
}
interface IOutputs {
  etatMonitoringList: IEtatMonitoringOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringStateService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getMonitoringStateList(inputs: IMonitoringStateServiceInputs): Promise<IMonitoringState[]> {
    const url = this.baseUrl + '/apiCockpit/etatMonitoring';
    const monitoringStateList: IMonitoringState[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.etatMonitoringList.map((output: IEtatMonitoringOut) => {
          const monitoringState: IMonitoringState = {
            id: output.idEtatMonitoring,
            state: this.mapping.mapState(output.etat),
            monitoring: this.mapping.mapMonitoring(output.monitoring),
            error: output.erreur,
            stackTrace: output.stackTrace,
            creationDate: output.dateCreation
          };
          monitoringStateList.push(monitoringState);
        });
      })
    ).toPromise().then(() => {
      return monitoringStateList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
