import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { IStateServiceInputs, StateService } from 'src/app/services/API/cockpit/state.service';
import { IState } from 'src/app/shared/models/state';
import { IMonitoring } from 'src/app/shared/models/monitoring';
import { InstanceMonitoringService, IInstanceMonitoringServiceInputs } from 'src/app/services/API/cockpit/instance-monitoring.service';
import { IInstanceMonitoring } from 'src/app/shared/models/instance-monitoring';

@Component({
  selector: 'app-monitoring-filter-bar',
  templateUrl: './monitoring-filter-bar.component.html',
  styleUrls: ['./monitoring-filter-bar.component.scss']
})
export class MonitoringFilterBarComponent implements OnInit {
  @Input() completeFlows: ICompleteFlow[];
  @Input() instanceMonitorings: IInstanceMonitoring[];
  @Input() selectedCompleteFlowId: string;
  @Input() selectedInstanceMonitoringId: any;
  selectedState: IState;
  nullState: IState = {
    color: null,
    icon: null,
    id: null,
    label: 'Tous',
    name: 'null',
    niveauDeCriticite: null,
  };
  states: IState[];
  @Output() selectedCompleteFlowIdEvent = new EventEmitter<string>();
  @Output() selectedStateEvent = new EventEmitter<IState>();
  @Output() selectedInstanceMonitoringEvent = new EventEmitter<any>();

  constructor(
    private loaderService: LoaderService,
    private stateService: StateService,
    private instanceMonitoringService: InstanceMonitoringService,
  ) { }

  ngOnInit(): void {
    this.getStateList(null);
    if (this.selectedCompleteFlowId) {
      this.slelectCompleteFlow(this.selectedCompleteFlowId, true).then(() => {
        if (this.selectedInstanceMonitoringId) {
          this.selectMonitoring(this.selectedInstanceMonitoringId);
        }
      });
    }
  }

  async slelectCompleteFlow(completeFlowId: string, init?: boolean) {
    if (!init) {
      this.selectedInstanceMonitoringId = 0;
      this.selectedInstanceMonitoringEvent.emit(0);
    }
    let input = {} as IInstanceMonitoringServiceInputs;
    if (completeFlowId !== 'ALL') {
      input = {
        etatInstanceFluxCompletList: [{
          etatInstanceFluxComplet: {
            idFluxComplet: completeFlowId,
          }
        }]
      } as IInstanceMonitoringServiceInputs;
    }
    return this.getInstanceMonitoringList(input).then(() => {
      return this.selectedCompleteFlowIdEvent.emit(completeFlowId);
    });
  }
  selectState(state: IState) {
    this.selectedStateEvent.emit(state);
  }
  selectMonitoring(instanceMonitoringId: string) {
    this.selectedInstanceMonitoringEvent.emit(instanceMonitoringId);
  }

  getStateList(inputs: IStateServiceInputs) {
    const loadingElement = this.loaderService.addLoadingElement();
    this.stateService.getStateList(inputs).then((results: IState[]) => {
      this.selectedState = this.nullState;
      this.states = [];
      this.states.push(this.nullState);
      this.states = this.states.concat(results);
      this.loaderService.removeLoadingElement(loadingElement);
    });
  }

  async getInstanceMonitoringList(inputs: IInstanceMonitoringServiceInputs) {
    inputs.orderBy = [{
      field: 'instanceMonitoring.dateCreation',
      direction: 'DESC'
    }];
    const loadingElement = this.loaderService.addLoadingElement();
    return this.instanceMonitoringService.getInstanceMonitoringList(inputs).then((results: any[]) => {
      this.instanceMonitorings = results;
      this.loaderService.removeLoadingElement(loadingElement);
    });
  }
}
