import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading: boolean;

  constructor(
    private loaderService: LoaderService
  ) {
    this.loaderService.loadingStateObs.subscribe((loadingState) => {
      this.isLoading = loadingState;
    });
  }

  ngOnInit(): void {
  }
}
