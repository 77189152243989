<div class="tableau">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="nomFlux"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="businessObject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Business object</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithId(element)"
        >
          {{ element.businessObject }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="nomFlux">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="onGoFlowTrackingWithId(element)"
      >
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithId(element)"
        >
          {{ element.name }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalFlux">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux Executé</th>
      <td mat-cell *matCellDef="let element" align="center">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithId(element)"
        >
          {{ element.totalFlux }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalFluxSucces">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux en succès</th>
      <td mat-cell *matCellDef="let element" align="center">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithState(element, 'SUCCES')"
        >
          {{ element.totalFluxSuccess }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalFluxErreur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux en erreurs</th>
      <td
        mat-cell
        *matCellDef="let element"
        align="center"
        [ngStyle]="{
          color: element.totalFluxErrors !== '0' ? 'red' : 'initial'
        }"
      >
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithState(element, 'ERREUR')"
        >
          {{ element.totalFluxErrors }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalDemiFlux">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Demi-Flux Executé
      </th>
      <td mat-cell *matCellDef="let element" align="center">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithId(element)"
        >
          {{ element.totalDemiFlux }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalDemiFluxSucces">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Demi-Flux en succès
      </th>
      <td mat-cell *matCellDef="let element" align="center">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithState(element, 'SUCCES')"
        >
          {{ element.totalDemiFluxSuccess }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalDemiFluxErreur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Erreurs à traiter
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        align="center"
        [ngStyle]="{
          color: element.totalFluxErrors !== '0' ? 'red' : 'initial'
        }"
      >
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithState(element, 'ERREUR')"
        >
          {{ element.totalDemiFluxErrors }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="totalDemiFluxResoumis">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Demi-Flux resoumis
      </th>
      <td mat-cell *matCellDef="let element" align="center">
        <button
          mat-button
          class="button-tab"
          (click)="onGoFlowTrackingWithState(element, 'RESOUMIS')"
        >
          {{ element.totalDemiFluxResoumis }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="row-hover"
      *matRowDef="let row; columns: displayedColumns"
      matTooltip="Voir le suivi"
      matTooltipPosition="right"
      matTooltipShowDelay="500"
      style="cursor: pointer"
    ></tr>
  </table>

  <div mat-row style="height: 60px; float: right; margin-top: 5px">
    <mat-form-field style="padding-right: 20px">
      <mat-select
        placeholder="Items par page"
        required
        #period
        name="taillePage"
        [(ngModel)]="pagination.taillePage"
        (ngModelChange)="paginationTaillePageChanged()"
      >
        <mat-option value="10"> 10 </mat-option>
        <mat-option value="20"> 20 </mat-option>
        <mat-option value="30"> 30 </mat-option>
      </mat-select>
    </mat-form-field>
    {{ pagination.firstRow }} - {{ pagination.lastRow }} of
    {{ pagination.totalRows }}
    <a
      mat-icon-button
      matTooltip="first"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="
        pagination.currentPage == '1' || pagination.currentPage == '0'
      "
      (click)="onGoFirst()"
      >❮❮</a
    >
    <a
      mat-icon-button
      matTooltip="previous"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="
        pagination.currentPage == '1' || pagination.currentPage == '0'
      "
      (click)="onGoPrevious()"
      >❮</a
    >
    <mat-form-field class="page-input">
      <input matInput autocomplete="off" type="number" min="1" [max]="pagination.totalPage" [(ngModel)]="pagination.currentPage" (change)="onGoPage($event.target.value)" >
    </mat-form-field>
    / {{ pagination.totalPage }}
    <a
      mat-icon-button
      matTooltip="next"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage"
      (click)="onGoNext()"
      >❯</a
    >
    <a
      mat-icon-button
      matTooltip="last"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage"
      (click)="onGoLast()"
      >❯❯</a
    >
  </div>
</div>
