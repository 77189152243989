import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IDomaineCommon, IApplicationCommon } from './models/common';
import {
  IOrderBy,
  IMonitoringIn,
  IInstanceMonitoringIn,
  IFluxCompletIn,
  IDemiFluxIn,
  IPagination,
  ILastEtatMonitoringIn,
  IBusinessDataMonitoringListIn
} from './models/in';

export interface IMonitoringCountServiceInputs {
  monitoring: IMonitoringIn;
  lastEtatMonitoring: ILastEtatMonitoringIn;
  instanceMonitoring: IInstanceMonitoringIn;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  businessDataMonitoringList: IBusinessDataMonitoringListIn[];
  pagination: IPagination;
  orderBy: IOrderBy[];
}
interface IOutputs {
  count: string;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringCountService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getMonitoringCount(inputs: IMonitoringCountServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/monitoringCount';
    return this.http.post(url, inputs).toPromise().then((outputs: IOutputs) => {
      return outputs.count;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
