<app-flow-tracking-filter-bar
  *ngIf="completFlowDone"
  [completeFlows]="completeFlows"
  [id]="id"
  [state]="state"
  (resetEvent)="onResetEvent()"
  (searchEvent)="onSearchEvent($event)"
></app-flow-tracking-filter-bar>
<app-flow-tracking-flows-tab
  *ngIf="completFlowDone"
  [completeFlows]="completeFlows"
  [search]="search"
>
</app-flow-tracking-flows-tab>
