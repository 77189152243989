import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IContactCommon } from './models/common';
import { IOrderBy } from './models/in';
import { MappingService } from './utils/mapping.service';
import { IContact } from 'src/app/shared/models/contact';

export interface IContactServiceInputs {
  contact: IContactCommon;
  orderBy: IOrderBy[];
}
interface IOutputs {
  contactList: IContactCommon[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getDomaineContactList(inputs: IContactServiceInputs): Promise<IContact[]> {
    const url = this.baseUrl + '/apiCockpit/contact';
    const contactList: IContact[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.contactList.map((output: IContactCommon) => {
          contactList.push(this.mapping.mapContact(output));
        });
      })
    ).toPromise().then(() => {
      return contactList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
