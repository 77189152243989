import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IEtatCommon, IDomaineCommon } from './models/common';
import { IFluxCompletIn, IInstanceMonitoringIn } from './models/in';


export interface IEtatInstanceFluxCompletCountServiceInputs {
  instanceMonitoring: IInstanceMonitoringIn;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  etat: IEtatCommon;
}
interface IOutputs {
  count: string;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class EtatInstanceFluxCompletCountService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getEtatInstanceFluxCompletCount(inputs: IEtatInstanceFluxCompletCountServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/etatInstanceFluxCompletCount';
    return this.http.post(url, inputs).toPromise().then((outputs: IOutputs) => {
      return outputs.count;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
