<div class="tableau">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="lastEtatMonitoring.dateCreation" matSortDirection="desc" matSortDisableClear >
    <ng-container matColumnDef="application.nom">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header> Application </th>
      <td mat-cell *matCellDef="let element"> {{element.halfFlow.application.name}} </td>
    </ng-container>
    <ng-container matColumnDef="lastEtatMonitoring.dateCreation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date du dernier état </th>
      <td mat-cell *matCellDef="let element"> {{element.lastMonitoringState.creationDate | date: 'dd/MM/yyyy HH:mm:ss.SSS'}} </td>
    </ng-container>
    <ng-container matColumnDef="lastEtatMonitoring.niveauDeCriticiteEtat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Etat </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
        [ngStyle]="{'color':  element.lastMonitoringState.state.color}"
        [matTooltip]="element.lastMonitoringState.state.label"
        >
          {{ element.lastMonitoringState.state.icon }}
        </mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="bussinessData">
      <th mat-header-cell *matHeaderCellDef> Bussiness data </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="openDialog(element.uuidMonitoring)">
          <mat-icon>view_list</mat-icon>
        </button>
      </td>
    </ng-container>
     <!-- <ng-container matColumnDef="renvoyer">
      <th mat-header-cell *matHeaderCellDef> Renvoyer </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [disabled]="element.state.id !== '2'" (click)="resubmit(element.monitoring.uuidMonitoring)">
          <mat-icon>cached</mat-icon>
        </button>
         <button mat-button [disabled]="element.state.name !== 'ERREUR' && element.state.name !== 'SUCCES'"
          (click)="resubmit(element.monitoring.uuidMonitoring)">
          <mat-icon>cached</mat-icon>
        </button>
      </td>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="row-hover" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div mat-row style="height: 60px; float: right; margin-top: 5px">
    <mat-form-field style="padding-right: 20px">
      <mat-select placeholder="Items par page" required #period="ngModel" name="taillePage"
        [(ngModel)]="pagination.taillePage" (ngModelChange)="paginationTaillePageChanged()">
        <mat-option value="50">
          50
        </mat-option>
        <mat-option value="100">
          100
        </mat-option>
        <mat-option value="500">
          500
        </mat-option>
        <mat-option value="1000">
          1000
        </mat-option>
      </mat-select>
    </mat-form-field>
    {{pagination.firstRow}} - {{pagination.lastRow}} of {{pagination.totalRows}}
    <a mat-icon-button matTooltip="first" matTooltipPosition="below" matTooltipShowDelay="500" style="margin: 0 2px"
      [disabled]="pagination.currentPage == '1' || pagination.currentPage == '0'" (click)="onGoFirst()">❮❮</a>
    <a mat-icon-button matTooltip="previous" matTooltipPosition="below" matTooltipShowDelay="500" style="margin: 0 2px"
      [disabled]="pagination.currentPage == '1' || pagination.currentPage == '0'" (click)="onGoPrevious()">❮</a>
      <mat-form-field class="page-input">
        <input matInput autocomplete="off" type="number" min="1" [max]="pagination.totalPage" [(ngModel)]="pagination.currentPage" (change)="onGoPage($event.target.value)" >
      </mat-form-field>
      / {{pagination.totalPage}}
    <a mat-icon-button matTooltip="next" matTooltipPosition="below" matTooltipShowDelay="500" style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage" (click)="onGoNext()">❯</a>
    <a mat-icon-button matTooltip="last" matTooltipPosition="below" matTooltipShowDelay="500" style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage" (click)="onGoLast()">❯❯</a>
  </div>
</div>
