import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  IEtatMonitoringIn,
  IMonitoringIn,
  IInstanceMonitoringIn,
  IDemiFluxIn,
  IFluxCompletIn,
  IPagination,
  IOrderBy,
  ILastEtatMonitoringIn
} from './models/in';
import { IEtatCommon, IApplicationCommon, IDomaineCommon } from './models/common';

export interface IMonitoringStateCountServiceInputs {
  etatMonitoring: IEtatMonitoringIn;
  etat: IEtatCommon;
  monitoring: IMonitoringIn;
  instanceMonitoring: IInstanceMonitoringIn;
  lastEtatMonitoring: ILastEtatMonitoringIn;
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  pagination: IPagination;
  orderBy: IOrderBy[];
}
interface IOutputs {
  count: string;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringStateCountService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getMonitoringStateCount(inputs: IMonitoringStateCountServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/etatMonitoringCount';
    return this.http.post(url, inputs).toPromise().then((outputs: IOutputs) => {
      return outputs.count;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
