<div class="tableau">
  <button
    mat-raised-button
    [disabled]="!selection.selected.length"
    (click)="resubmit()"
  >
    <mat-icon>cached</mat-icon>
    Rejouer
  </button>
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="monitoring.dateCreation"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          *ngIf="!isMasterEnable()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          *ngIf="row.lastMonitoringState.state.name === 'ERREUR'"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="fluxComplet.nom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Flux</th>
      <td mat-cell *matCellDef="let element">
        {{ element.halfFlow.completeFlow.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="application.nom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Application</th>
      <td mat-cell *matCellDef="let element">
        {{ element.halfFlow.application.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="monitoring.dateCreation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date de création
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.creationDate | date: "dd/MM/yyyy HH:mm:ss.SSS" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lastEtatMonitoring.niveauDeCriticiteEtat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Etat</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          [ngStyle]="{ color: element.lastMonitoringState.state.color }"
          [matTooltip]="element.lastMonitoringState.state.label"
        >
          {{ element.lastMonitoringState.state.icon }}
        </mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="lien">
      <th mat-header-cell *matHeaderCellDef>Voir le monitoring</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          matTooltip="Voir le monitoring"
          matTooltipPosition="right"
          matTooltipShowDelay="500"
          style="cursor: pointer"
          (click)="OnGoMonitoring(element)"
          >link</mat-icon
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
          fxLayout="column"
        >
          <table mat-table [dataSource]="dataSourceDetail">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>date</th>
              <td mat-cell *matCellDef="let data">
                {{ data.creationDate | date: "dd/MM/yyyy HH:mm:ss.SSS" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="etat">
              <th mat-header-cell *matHeaderCellDef>etat</th>
              <td mat-cell *matCellDef="let data">
                {{ data.state.label }}
              </td>
            </ng-container>
            <ng-container matColumnDef="erreur">
              <th mat-header-cell *matHeaderCellDef>erreur</th>
              <td mat-cell *matCellDef="let data">
                <p *ngIf="data.error">
                  {{ data.error | slice: 0:100 }}...
                  <mat-icon
                    style="cursor: pointer; font-size: 15px; padding-left: 5px"
                    matTooltip="copier dans le presse papier"
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    (click)="copyMessage(data.error)"
                  >
                    file_copy</mat-icon
                  >
                </p>
              </td>
            </ng-container>
            <ng-container matColumnDef="stackTrack">
              <th mat-header-cell *matHeaderCellDef>stackTrace</th>
              <td mat-cell *matCellDef="let data">
                <p *ngIf="data.stackTrace">
                  {{ data.stackTrace | slice: 0:100 }}...
                  <mat-icon
                    style="cursor: pointer; font-size: 15px; padding-left: 5px"
                    matTooltip="copier dans le presse papier"
                    matTooltipPosition="right"
                    matTooltipShowDelay="500"
                    (click)="copyMessage(data.stackTrace)"
                  >
                    file_copy</mat-icon
                  >
                </p>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDetailColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedDetailColumns"
              style="height: 50px"
            ></tr>
          </table>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandElement(element)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <div style="height: 60px; float: right; margin-top: 5px">
    <span
      *ngIf="selection && selection.selected"
      style="height: 60px; margin-right: 15px"
    >
      <span *ngIf="selection.selected.length === 1">
        {{ selection.selected.length }} élément sélectionné
      </span>
      <span *ngIf="selection.selected.length > 1">
        {{ selection.selected.length }} éléments sélectionnés
      </span>
    </span>
    <mat-form-field style="padding-right: 20px">
      <mat-select
        placeholder="Items par page"
        required
        #period="ngModel"
        name="taillePage"
        [(ngModel)]="pagination.taillePage"
        (ngModelChange)="paginationTaillePageChanged()"
      >
        <mat-option value="20"> 20 </mat-option>
        <mat-option value="50"> 50 </mat-option>
        <mat-option value="100"> 100 </mat-option>
      </mat-select>
    </mat-form-field>
    {{ pagination.firstRow }} - {{ pagination.lastRow }} of
    {{ pagination.totalRows }}
    <a
      mat-icon-button
      matTooltip="first"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="
        pagination.currentPage == '1' || pagination.currentPage == '0'
      "
      (click)="onGoFirst()"
      >❮❮</a
    >
    <a
      mat-icon-button
      matTooltip="previous"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="
        pagination.currentPage == '1' || pagination.currentPage == '0'
      "
      (click)="onGoPrevious()"
      >❮</a
    >
    <mat-form-field class="page-input">
      <input
        matInput
        autocomplete="off"
        type="number"
        min="1"
        [max]="pagination.totalPage"
        [(ngModel)]="pagination.currentPage"
        (change)="onGoPage($event.target.value)"
      />
    </mat-form-field>
    / {{ pagination.totalPage }}
    <a
      mat-icon-button
      matTooltip="next"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage"
      (click)="onGoNext()"
      >❯</a
    >
    <a
      mat-icon-button
      matTooltip="last"
      matTooltipPosition="below"
      matTooltipShowDelay="500"
      style="margin: 0 2px"
      [disabled]="pagination.currentPage == pagination.totalPage"
      (click)="onGoLast()"
      >❯❯</a
    >
  </div>
</div>
