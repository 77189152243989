import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/API/cockpit/auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class IsInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let changedRequest = request.clone();
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(environment.settings.user + ':' + environment.settings.password),
      'Content-Type': 'application/json',
      Token: this.authService.user ? this.authService.user.token : ''
    });
    changedRequest = request.clone({ headers });
    return next.handle(changedRequest).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // something if okay
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            if (err.error.errorDetail === 'Token is invalid') {
              this.authService.logoutUser();
            }
            if (err.error.errorDetail === 'Token is missing') {
              this.authService.logoutUser();
            }
          }
        }
      }));
  }
}
