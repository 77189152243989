import { Component, OnInit, OnChanges } from '@angular/core';
import { CompleteFlowService, ICompleteFlowServiceInputs } from 'src/app/services/API/cockpit/complete-flow.service';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { LoaderService } from 'src/app/services/loader.service';
import { StateService, IStateServiceInputs } from 'src/app/services/API/cockpit/state.service';
import { IState } from 'src/app/shared/models/state';
import { IEtatInstanceFluxCompletIn } from 'src/app/services/API/cockpit/models/in';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  completeFlows: ICompleteFlow[];
  completeFlowsError: ICompleteFlow[];
  selectedCompleteFlows: ICompleteFlow[];
  states: IState[];
  constructor(
    private loaderService: LoaderService,
    private completeFlowServices: CompleteFlowService,
    private stateService: StateService,
  ) { }

  ngOnInit() {
    this.getStateList(null).then(states => {
      this.states = states;
      const errorStateId = states.find(state => state.name === 'ERREUR').id;
      const input: ICompleteFlowServiceInputs = {
        fluxComplet: {
          estActif: 'true',
        },
        orderBy: [{
          field: 'fluxComplet.nom'
        }]
      } as ICompleteFlowServiceInputs;
      this.getCompleFlowList(input).then((flows) => {
        this.completeFlows = flows;
        input.etatInstanceFluxCompletList = [
          {
            etatInstanceFluxComplet: {
              idEtat: errorStateId
            } as IEtatInstanceFluxCompletIn
          }
        ];
        this.getCompleFlowList(input).then((flowsError) => {
          this.completeFlowsError = flowsError;
        });
      });
    });
  }

  onFilterBarEvent(selectedCompleteFlows: ICompleteFlow[]) {
    this.selectedCompleteFlows = [];
    selectedCompleteFlows.map(selectedCompleteFlow => {
      this.selectedCompleteFlows.push(selectedCompleteFlow);
    });
  }

  getStateList(inputs: IStateServiceInputs): Promise<IState[]> {
    return this.stateService.getStateList(inputs).then((results: IState[]) => {
      return results;
    });
  }

  getCompleFlowList(inputs: ICompleteFlowServiceInputs): Promise<ICompleteFlow[]> {
    const loadindElement = this.loaderService.addLoadingElement();
    return this.completeFlowServices.getCompleteFlowList(inputs).then((results: ICompleteFlow[]) => {
      this.loaderService.removeLoadingElement(loadindElement);
      return results;
    });
  }

}
