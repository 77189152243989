import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IHalfFlow } from 'src/app/shared/models/half-flow';
import { IApplicationCommon, IDomaineCommon } from './models/common';
import { IDemiFluxIn, IFluxCompletIn, IOrderBy } from './models/in';
import { IDemiFluxOut } from './models/out';
import { MappingService } from './utils/mapping.service';

export interface IHalfFlowServiceInputs {
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  orderBy: IOrderBy[];
}
interface IOutputs {
  demiFluxList: IDemiFluxOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class HalfFlowService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getHalfFlowList(inputs: IHalfFlowServiceInputs): Promise<IHalfFlow[]> {
    const url = this.baseUrl + '/apiCockpit/demiFlux';
    const halfFlowList: IHalfFlow[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.demiFluxList.map((output: IDemiFluxOut) => {
          const halfFlow: IHalfFlow = {
            id: output.idDemiFlux,
            serviceName: output.nomService,
            application: {
              id: output.application.idApplication,
              name: output.application.nom,
            },
            completeFlow: this.mapping.mapCompleteFlow(output.fluxComplet)
          };
          halfFlowList.push(halfFlow);
        });
      })
    ).toPromise().then(() => {
      return halfFlowList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
