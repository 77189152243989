import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { CompleteFlowService, ICompleteFlowServiceInputs } from 'src/app/services/API/cockpit/complete-flow.service';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { IState } from 'src/app/shared/models/state';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {
  completeFlows: ICompleteFlow[];
  selectedCompleteFlowId: string;
  selectedMonitoringId: any;
  selectedState: IState;

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private completeFlowService: CompleteFlowService,
  ) { }

  ngOnInit(): void {
    this.getCompleFlowList({
      fluxComplet: {
        estActif: 'true',
      },
      orderBy: [{
        field: 'fluxComplet.nom',
        direction: 'asc'
      }],
    } as ICompleteFlowServiceInputs);
    this.route.paramMap.subscribe(params => {
      this.selectedCompleteFlowId = params.get('completeFlowId') ? params.get('completeFlowId') : 'ALL';
      this.selectedMonitoringId = params.get('instanceMonitoringId') ? params.get('instanceMonitoringId') : 0;
    });
  }

  getCompleFlowList(inputs: ICompleteFlowServiceInputs) {
    const loadindElement = this.loaderService.addLoadingElement();
    this.completeFlowService.getCompleteFlowList(inputs).then((results: ICompleteFlow[]) => {
      this.completeFlows = results;
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

  onSlelectCompleteFlowId(completeFlowId: string) {
    this.selectedCompleteFlowId = null;
    this.selectedCompleteFlowId = completeFlowId;
  }
  onSelectInstanceMonitoring(instanceMonitoringId: string) {
    this.selectedMonitoringId = null;
    this.selectedMonitoringId = instanceMonitoringId;
  }
  onSlelectState(state: IState) {
    this.selectedState = { ...state };
  }
}
