<div class="selectArea" fxLayoutGap="1%" fxLayout="row" fxLayoutAlign="center center" >
  <mat-form-field style="width:100%">
    <mat-chip-list #chipList>
      <div *ngIf="completeFlows && completeFlows.length !== selectedCompleteFlows.length">
        <mat-chip *ngFor="let completeFlow of selectedCompleteFlows" [selectable]="selectable" [removable]="removable"
          (removed)="remove(completeFlow.id)">
          {{completeFlow.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </div>
      <div *ngIf="completeFlows && completeFlows.length === selectedCompleteFlows.length">
        <mat-chip [selectable]="selectable" [removable]="removable" (removed)="reset()">
          Tous
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </div>
      <input (click)="focuscompleteFlowInput()" placeholder="Sélectionner un ou plusieurs flux" #completeFlowInput [formControl]="formCtrl"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="false">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option value="all" *ngIf="completeFlows && completeFlows.length !== selectedCompleteFlows.length">
        Tous
      </mat-option>
      <mat-option *ngFor="let completeFlow of selectableCompleteFlows" [value]="completeFlow.id">
        {{completeFlow.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button type="button" mat-raised-button class="btn btn-primary" (click)="reset()">Réinitialiser</button>
</div>
