import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { InstanceMonitoringCountService } from 'src/app/services/API/cockpit/instance-monitoring-count.service';
import { IInstanceMonitoringServiceInputs } from 'src/app/services/API/cockpit/instance-monitoring.service';
import { IMonitoringStateCountServiceInputs, MonitoringStateCountService } from 'src/app/services/API/cockpit/monitoring-state-count.service';
import { ICompleteFlowServiceInputs, CompleteFlowService } from 'src/app/services/API/cockpit/complete-flow.service';
import { MonitoringCountService, IMonitoringCountServiceInputs } from 'src/app/services/API/cockpit/monitoring-count.service';
import { EtatInstanceFluxCompletCountService, IEtatInstanceFluxCompletCountServiceInputs } from 'src/app/services/API/cockpit/etat-instance-flux-complet-count.service';

@Component({
  selector: 'app-dashboard-indicators',
  templateUrl: './dashboard-indicators.component.html',
  styleUrls: ['./dashboard-indicators.component.scss']
})
export class DashboardIndicatorsComponent implements OnInit {

  indicators: any[];

  constructor(
    private loaderService: LoaderService,
    private instanceMonitoringCountService: InstanceMonitoringCountService,
    private monitoringStateCountService: MonitoringStateCountService,
    private completeFlowService: CompleteFlowService,
    private monitoringCountService: MonitoringCountService,
    private etatInstanceFluxCompletCountService: EtatInstanceFluxCompletCountService,
  ) { }

  async ngOnInit() {
    this.indicators = [
      {
        count: '?',
        label: 'Flux en Succés',
        icon: 'done',
        fontColor: 'white',
        bgColor: '#488a64'
      },
      {
        count: '?',
        label: 'Demi-flux en Succés',
        icon: 'done',
        fontColor: 'white',
        bgColor: '#27ae60'
      },
      {
        count: '?',
        label: 'Flux en Erreurs',
        icon: 'cancel',
        fontColor: 'white',
        bgColor: '#c92e4b'
      },
      {
        count: '?',
        label: 'Erreurs à traiter',
        icon: 'cancel',
        fontColor: 'white',
        bgColor: '#c0392b'
      },
      {
        count: '?',
        label: 'Flux Exécutés',
        icon: 'power_settings_new',
        fontColor: 'white',
        bgColor: '#16a085'
      },
      {
        count: '?',
        label: 'Demi-flux executé',
        icon: 'filter_none',
        fontColor: 'white',
        bgColor: '#2980b9'
      },
      {
        count: '?',
        label: 'Flux Parametrés',
        icon: 'settings',
        fontColor: 'white',
        bgColor: '#2c3e50'
      },
      {
        count: '?',
        label: 'Demi-flux Resoumis',
        icon: 'refresh',
        fontColor: 'white',
        bgColor: '#8e44ad'
      },
    ];
    await this.fillIndicatorsCount();
  }

  async fillIndicatorsCount() {
    this.indicators[0].count = await this.getEtatInstanceFluxCompletCount({
      fluxComplet: {
        estActif: 'true',
      },
      etat: {
        nom: 'SUCCES'
      }
    } as IEtatInstanceFluxCompletCountServiceInputs).catch(() => 0);
    this.indicators[1].count = await this.getMonitoringCount({
      fluxComplet: {
        estActif: 'true',
      },
      lastEtatMonitoring: {
        nomEtat: 'SUCCES'
      }
    } as IMonitoringCountServiceInputs).catch(() => 0);
    this.indicators[2].count = await this.getEtatInstanceFluxCompletCount({
      fluxComplet: {
        estActif: 'true',
      },
      etat: {
        nom: 'ERREUR'
      }
    } as IEtatInstanceFluxCompletCountServiceInputs).catch(() => 0);
    this.indicators[3].count = await this.getMonitoringCount({
      fluxComplet: {
        estActif: 'true',
      },
      lastEtatMonitoring: {
        nomEtat: 'ERREUR'
      }
    } as IMonitoringCountServiceInputs).catch(() => 0);
    this.indicators[4].count = await this.getEtatInstanceFluxCompletCount({
      fluxComplet: {
        estActif: 'true',
      }
    } as IEtatInstanceFluxCompletCountServiceInputs).catch(() => 0);
    this.indicators[5].count = await this.getMonitoringCount({
      fluxComplet: {
        estActif: 'true',
      }
    } as IMonitoringCountServiceInputs).catch(() => 0);
    this.indicators[6].count = await this.getCompleteFlowCount({
      fluxComplet: {
        estActif: 'true',
      }
    } as ICompleteFlowServiceInputs).catch(() => 0);
    this.indicators[7].count = await this.getMonitoringCount({
      fluxComplet: {
        estActif: 'true',
      },
      lastEtatMonitoring: {
        nomEtat: 'RESOUMIS'
      }
    } as IMonitoringCountServiceInputs).catch(() => 0);
  }

  async getMonitoringStateCount(inputs: IMonitoringStateCountServiceInputs) {
    return await this.monitoringStateCountService.getMonitoringStateCount(inputs).then((result) => {
      return result;
    });
  }

  async getCompleteFlowCount(inputs: ICompleteFlowServiceInputs) {
    return await this.completeFlowService.getCompleteFlowCount(inputs).then((result) => {
      return result;
    });
  }

  async getMonitoringCount(inputs: IMonitoringCountServiceInputs) {
    return await this.monitoringCountService.getMonitoringCount(inputs).then((result) => {
      return result;
    });
  }

  async getEtatInstanceFluxCompletCount(inputs: IEtatInstanceFluxCompletCountServiceInputs) {
    return await this.etatInstanceFluxCompletCountService.getEtatInstanceFluxCompletCount(inputs).then((result) => {
      return result;
    });
  }

}
