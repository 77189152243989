<div fxLayout.gt-md="row" fxLayout="column">
  <div fxFlex.gt-md="500px">
    <app-dashboard-indicators></app-dashboard-indicators>
  </div>
  <div fxFlex="100">
    <app-dashboard-filter-bar [completeFlows]="completeFlows" [completeFlowsError]="completeFlowsError" (selectedCompleteFlowsEvent)="onFilterBarEvent($event)">
    </app-dashboard-filter-bar>
    <app-dashboard-flows-tab [completeFlows]="selectedCompleteFlows" [states]="states"></app-dashboard-flows-tab>
  </div>
</div>
