import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-indicator-card',
  templateUrl: './dashboard-indicator-card.component.html',
  styleUrls: ['./dashboard-indicator-card.component.scss']
})
export class DashboardIndicatorCardComponent {

  @Input() count: number;
  @Input() label: string;
  @Input() icon: string;
  @Input() fontColor: string;
  @Input() bgColor: string;
  @Input() borderColor: string;

}
