<form name="form">
  <div fxLayout="row" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="start center">
    <mat-form-field fxFlex="31">
      <mat-select placeholder="Sélectionner un flux" (selectionChange)="slelectCompleteFlow($event.value)"
        name="nomFlux" [(ngModel)]="selectedCompleteFlowId">
        <mat-option value="ALL">Tous</mat-option>
        <mat-option *ngFor="let completeFlow of completeFlows" [value]="completeFlow.id">
          {{completeFlow.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="31">
      <mat-select placeholder="Statut" (selectionChange)="selectState($event.value)" name="statut"
        [(ngModel)]="selectedState">
        <mat-option *ngFor="let state of states" [value]="state">
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="31">
      <mat-select placeholder="Sélectionner une date de démarage"
        (selectionChange)="selectMonitoring($event.value)" name="dateCreation"
        [(ngModel)]="selectedInstanceMonitoringId" [disabled]="!instanceMonitorings">
        <mat-option [value]="0">Tous</mat-option>
        <mat-option *ngFor="let instanceMonitoring of instanceMonitorings"
          [value]="instanceMonitoring.uuidInstanceMonitoring">
          {{ instanceMonitoring.creationDate | date: 'dd/MM/yyyy HH:mm:ss.SSS' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
