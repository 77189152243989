import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDomaineCommon } from './models/common';
import { IOrderBy, IInstanceMonitoringIn, IFluxCompletIn, IPagination, IEtatInstanceFluxCompletListIn } from './models/in';
import { IInstanceMonitoring } from 'src/app/shared/models/instance-monitoring';
import { IInstanceMonitoringOut } from './models/out';
import { MappingService } from './utils/mapping.service';

export interface IInstanceMonitoringServiceInputs {
  instanceMonitoring: IInstanceMonitoringIn;
  etatInstanceFluxCompletList: IEtatInstanceFluxCompletListIn[];
  pagination: IPagination;
  orderBy: IOrderBy[];
}
interface IOutputs {
  instanceMonitoringList: IInstanceMonitoringOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class InstanceMonitoringService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getInstanceMonitoringList(inputs: IInstanceMonitoringServiceInputs): Promise<IInstanceMonitoring[]> {
    const url = this.baseUrl + '/apiCockpit/instanceMonitoring';
    const stateList: IInstanceMonitoring[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.instanceMonitoringList.map((output: IInstanceMonitoringOut) => {
          const state: IInstanceMonitoring = this.mapping.mapInstanceMonitoring(output);
          stateList.push(state);
        });
      })
    ).toPromise().then(() => {
      return stateList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
