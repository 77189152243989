import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loadingElements: string[];
  loadingState: Subject<boolean> = new Subject<boolean>();
  loadingStateObs: Observable<boolean> = this.loadingState.asObservable();

  constructor() {
    this.loadingElements = [];
  }

  updateLoadingState() {
    this.loadingState.next(this.loadingElements.length !== 0);
  }

  addLoadingElement(): string {
    const uuid = uuidv4();
    this.loadingElements.push(uuid);
    this.updateLoadingState();
    return uuid;
  }

  removeLoadingElement(elem: string): void {
    this.loadingElements.splice(this.loadingElements.indexOf(elem), 1);
    this.updateLoadingState();
  }

  clearLoadingElement(): void {
    this.loadingElements = [];
    this.updateLoadingState();
  }
}
