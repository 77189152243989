import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface IMonitoringStateResubmitInput {
  uuidMonitoring: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringStateResubmitService {
  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async monitoringStateResubmit(uuidMonitoringList: IMonitoringStateResubmitInput[]) {
    const url = this.baseUrl + '/apiCockpit/resubmit';
    return this.http.post(url, uuidMonitoringList)
      .toPromise().then((data) => {
        return data;
      }).catch(err => {
        console.error(err);
        throw err;
      });
  }
}
