import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString();
    return date.getFullYear() + '-' + (month.length < 2 ? '0' + month : month) + '-' + date.getDate();
  }

  formatDateString(dateString: string): string {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString();
    return date.getFullYear() + '-' + (month.length < 2 ? '0' + month : month) + '-' + date.getDate();
  }

  formatDateTimeString(dateTimeString: string): string {
    if (!dateTimeString) {
      return null;
    }
    const date = new Date(dateTimeString);
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
    const hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds = date.getSeconds().toString().length === 1 ? '0' + date.getSeconds() : date.getSeconds();
    return date.getFullYear() + '-' + (month.length < 2 ? '0' + month : month) + '-' + day + ' '
      + hours + ':' + minutes + ':' + seconds + '.000';
  }
}
