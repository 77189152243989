import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IBusinessData } from 'src/app/shared/models/business-data';
import { IApplicationCommon, IDomaineCommon } from './models/common';
import { IBusinessDataIn, IDemiFluxIn, IFluxCompletIn, IOrderBy } from './models/in';
import { IBusinessDataOut } from './models/out';
import { MappingService } from './utils/mapping.service';

export interface IBusinessDataServiceInputs {
  businessData: IBusinessDataIn;
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  orderBy: IOrderBy[];
}
interface IOutputs {
  businessDataList: IBusinessDataOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class BusinessDataService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getBusinessDataList(inputs: IBusinessDataServiceInputs): Promise<IBusinessData[]> {
    const url = this.baseUrl + '/apiCockpit/businessData';
    const businessDataList: IBusinessData[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.businessDataList.map((output: IBusinessDataOut) => {
          const businessData: IBusinessData = {
            key: output.cle,
            halfFlow: this.mapping.mapHalfFlow(output.demiFlux)
          };
          businessDataList.push(businessData);
        });
      })
    ).toPromise().then(() => {
      return businessDataList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
