import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDomaineCommon, IApplicationCommon } from './models/common';
import {
  IOrderBy,
  IMonitoringIn,
  IInstanceMonitoringIn,
  IFluxCompletIn,
  IDemiFluxIn,
  IPagination,
  ILastEtatMonitoringIn,
  IBusinessDataMonitoringListIn
} from './models/in';
import { IMonitoringOut } from './models/out';
import { IMonitoring } from 'src/app/shared/models/monitoring';
import { MappingService } from './utils/mapping.service';

export interface IMonitoringServiceInputs {
  monitoring: IMonitoringIn;
  instanceMonitoring: IInstanceMonitoringIn;
  lastEtatMonitoring: ILastEtatMonitoringIn;
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  demiFlux: IDemiFluxIn;
  application: IApplicationCommon;
  businessDataMonitoringList: IBusinessDataMonitoringListIn[];
  pagination: IPagination;
  orderBy: IOrderBy[];
}
interface IOutputs {
  monitoringList: IMonitoringOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getMonitoringList(inputs: IMonitoringServiceInputs): Promise<IMonitoring[]> {
    const url = this.baseUrl + '/apiCockpit/monitoring';
    const stateList: IMonitoring[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.monitoringList.map((output: IMonitoringOut) => {
          const monitoring: IMonitoring = this.mapping.mapMonitoring(output);
          stateList.push(monitoring);
        });
      })
    ).toPromise().then(() => {
      return stateList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
