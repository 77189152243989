import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { PrivateRoutingModule } from './private-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FlowTrackingModule } from './flow-tracking/flow-tracking.module';
import { MonitoringModule } from './monitoring/monitoring.module';


@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    PrivateRoutingModule,
    DashboardModule,
    FlowTrackingModule,
    MonitoringModule
  ]
})
export class PrivateModule { }
