import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDomaineCommon, IContactCommon } from './models/common';
import { IOrderBy, IDomaineContactIn } from './models/in';
import { IDomaineContactOut } from './models/out';
import { IContactDomaine } from 'src/app/shared/models/domaine-contact';
import { MappingService } from './utils/mapping.service';

export interface IDomaineContactServiceInputs {
  domaineContact: IDomaineContactIn;
  domaine: IDomaineCommon;
  contact: IContactCommon;
  orderBy: IOrderBy[];
}
interface IOutputs {
  domaineContactList: IDomaineContactOut[];
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class DomaineContactService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getDomaineContactList(inputs: IDomaineContactServiceInputs): Promise<IContactDomaine[]> {
    const url = this.baseUrl + '/apiCockpit/domaineContact';
    const contactDomainList: IContactDomaine[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: IOutputs) => {
        outputs.domaineContactList.map((output: IDomaineContactOut) => {
          const contactDomain: IContactDomaine = {
            domaine: {
              id: output.domaine.idDomaine,
              name: output.domaine.nom
            },
            contact: this.mapping.mapContact(output.contact),
            role: output.role
          };
          contactDomainList.push(contactDomain);
        });
      })
    ).toPromise().then(() => {
      return contactDomainList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
