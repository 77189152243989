import { Component, OnInit, Input, OnChanges, Output, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';

@Component({
  selector: 'app-dashboard-filter-bar',
  templateUrl: './dashboard-filter-bar.component.html',
  styleUrls: ['./dashboard-filter-bar.component.scss']
})
export class DashboardFilterBarComponent implements OnInit, OnChanges {
  @Input() completeFlows: ICompleteFlow[];
  @Input() completeFlowsError: ICompleteFlow[];
  init = true;
  @ViewChild('completeFlowInput', { static: false }) completeFlowInput: ElementRef<HTMLInputElement>;
  selectableCompleteFlows: ICompleteFlow[];
  selectedCompleteFlows: ICompleteFlow[];
  @Output() selectedCompleteFlowsEvent = new EventEmitter<ICompleteFlow[]>();
  formCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = false;
  removable = true;

  constructor() {
  }

  ngOnInit() {
    this.selectableCompleteFlows = [];
    this.selectedCompleteFlows = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.completeFlowsError) {
      const completeFlowsError: ICompleteFlow[] = changes.completeFlowsError.currentValue;
      if (this.selectedCompleteFlows) {
          this.selectedCompleteFlows = [];
          completeFlowsError.map(completeFlow => {
            this.selectedCompleteFlows.push(completeFlow);
          });
          this.completeFlows.map(completeFlow => {
            if (!completeFlowsError.find(f => f.id === completeFlow.id)) {
              this.selectableCompleteFlows.push(completeFlow);
            }
          });
          this.init = false;
          this.selectedCompleteFlowsEvent.emit(this.selectedCompleteFlows);
      }
    } else {
      if (this.selectedCompleteFlows && !this.init) {
        if (this.selectedCompleteFlows.length === 0) {
          this.selectedCompleteFlows = [];
          this.selectableCompleteFlows = [];
          this.completeFlows.map(completeFlow => {
            this.selectedCompleteFlows.push(completeFlow);
          });
          this.selectedCompleteFlowsEvent.emit(this.selectedCompleteFlows);
        }
      }
    }
  }

  remove(flowID: string) {
    this.selectableCompleteFlows.push(this.completeFlows.filter(completeFlow => completeFlow.id === flowID)[0]);
    this.selectedCompleteFlows.splice(this.selectedCompleteFlows.findIndex(completeFlow => completeFlow.id === flowID), 1);
    this.sort(this.selectableCompleteFlows, 'name');
    this.selectedCompleteFlowsEvent.emit(this.selectedCompleteFlows);
  }

  reset(): void {
    this.selectedCompleteFlows = [];
    this.selectableCompleteFlows = [];
    this.completeFlows.map(completeFlow => {
      this.selectableCompleteFlows.push(completeFlow);
    });
    this.selectedCompleteFlowsEvent.emit(this.selectedCompleteFlows);
  }

  focuscompleteFlowInput() {
    this.completeFlowInput.nativeElement.blur();
    this.completeFlowInput.nativeElement.focus();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    switch (value) {
      case 'all':
        this.selectedCompleteFlows = [];
        this.completeFlows.map(completeFlow => {
          this.selectedCompleteFlows.push(completeFlow);
        });
        this.selectableCompleteFlows = [];
        break;

      default:
        this.selectedCompleteFlows.push(this.selectableCompleteFlows.filter(completeFlow => completeFlow.id === value)[0]);
        this.selectableCompleteFlows = this.selectableCompleteFlows.filter(completeFlow => completeFlow.id !== value);
        break;
    }
    this.selectedCompleteFlowsEvent.emit(this.selectedCompleteFlows);
  }

  sort(completeFlows: ICompleteFlow[], field: string) {
    completeFlows.sort((a: ICompleteFlow, b: ICompleteFlow) => {
      return a[field] >= b[field] ? 1 : -1;
    });
  }

}
