import { FlowTrackingResubmitDialogComponent } from './../flow-tracking-resubmit-dialog/flow-tracking-resubmit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MonitoringStateResubmitService } from './../../../services/API/cockpit/monitoring-state-resubmit.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { MonitoringService, IMonitoringServiceInputs } from 'src/app/services/API/cockpit/monitoring.service';
import { MonitoringCountService } from 'src/app/services/API/cockpit/monitoring-count.service';
import { MonitoringStateService, IMonitoringStateServiceInputs } from 'src/app/services/API/cockpit/monitoring-state.service';
import { ISearch } from '../flow-tracking-filter-bar/flow-tracking-filter-bar.component';
import { IMonitoring } from 'src/app/shared/models/monitoring';
import { IMonitoringState } from 'src/app/shared/models/monitoring-state';
import { IPagination } from 'src/app/shared/models/pagination';
import { IOrderBy, IBusinessDataMonitoringListIn } from 'src/app/services/API/cockpit/models/in';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { DomaineContactService, IDomaineContactServiceInputs } from 'src/app/services/API/cockpit/domaine-contact.service';
import { IContactDomaine } from 'src/app/shared/models/domaine-contact';
import { AuthService } from 'src/app/services/API/cockpit/auth.service';

@Component({
  selector: 'app-flow-tracking-flows-tab',
  templateUrl: './flow-tracking-flows-tab.component.html',
  styleUrls: ['./flow-tracking-flows-tab.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FlowTrackingFlowsTabComponent implements OnInit, OnChanges {
  @Input() search: ISearch;
  @Input() completeFlows: ICompleteFlow[];
  monitorings: IMonitoring[];
  monitoringCount: string;
  displayedColumns: string[] = ['fluxComplet.nom', 'application.nom',
    'monitoring.dateCreation', 'lastEtatMonitoring.niveauDeCriticiteEtat', 'lien'];
  displayedDetailColumns: string[] = ['date', 'etat', 'erreur', 'stackTrack'];
  dataSource: MatTableDataSource<IMonitoring>;
  dataSourceDetail: MatTableDataSource<IMonitoringState>;
  expandedElement: IMonitoring;
  orderBy: IOrderBy[];
  pagination: IPagination;
  selection = new SelectionModel<IMonitoring>(true, []);
  public idDomaineSelectedFlow: string;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private monitoringService: MonitoringService,
    private monitoringCountService: MonitoringCountService,
    private monitoringStateService: MonitoringStateService,
    private monitoringStateResubmitService: MonitoringStateResubmitService,
    private domaineContactService: DomaineContactService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.setPagination('20');
    this.sortData({ active: 'monitoring.dateCreation', direction: 'desc' });
    this.tabUpdate();
  }

  ngOnChanges() {
    if (this.search) {
      if (this.pagination === undefined) {
        this.setPagination('20');
      }
      this.pagination.currentPage = '1';
      this.selection.clear();
      if (this.search.completeFlowId) {
        this.idDomaineSelectedFlow = this.completeFlows.find(f => f.id === this.search.completeFlowId).domaine.id;
        const inputs = {
          monitoring: {
            dateCreation: {
              dateDebut: this.search.startDate,
              dateFin: this.search.endDate
            }
          },
          fluxComplet: {
            idFluxComplet: this.search.completeFlowId
          },
          application: {
            idApplication: this.search.applicationId
          },
          lastEtatMonitoring: {
            idEtat: this.search.stateId
          },
          businessDataMonitoringList: []
        } as IMonitoringServiceInputs;
        this.search.businessDatas.map(searchBusinessData => {
          const businessDataMonitoring = {
            businessDataMonitoring: {
              cle: searchBusinessData.key,
              valeur: searchBusinessData.value
            }
          } as IBusinessDataMonitoringListIn;
          inputs.businessDataMonitoringList.push(businessDataMonitoring);
        });
        this.getMonitoringList(inputs).then(() => {
          this.tabUpdate();
        });
        this.getMonitoringCount(inputs);
      }
    }
  }

  expandElement(element: IMonitoring) {
    if (this.expandedElement != null && this.expandedElement.uuidMonitoring === element.uuidMonitoring) {
      this.subTabUpdate(null);
      this.expandedElement = null;
    }
    else {
      this.expandedElement = null;
      const inputs = {
        monitoring: {
          uuidMonitoring: element.uuidMonitoring
        }
      } as IMonitoringStateServiceInputs;
      this.getMonitoringStateList(inputs).then(results => {
        this.subTabUpdate(results);
        this.expandedElement = element;
      });
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  tabUpdate() {
    this.dataSource = new MatTableDataSource<IMonitoring>(this.monitorings);
    this.autoCheck();
  }

  subTabUpdate(monitoringStates: IMonitoringState[]) {
    this.dataSourceDetail = new MatTableDataSource<IMonitoringState>(monitoringStates);
  }

  async getMonitoringList(inputs: IMonitoringServiceInputs) {
    const loadindElement = this.loaderService.addLoadingElement();
    inputs.orderBy = this.orderBy;
    inputs.pagination = {
      offset: this.pagination.taillePage,
      startIndex: (+this.pagination.taillePage * (this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0)).toString()
    };
    return this.monitoringService.getMonitoringList(inputs).then((results: IMonitoring[]) => {
      this.monitorings = results;
      this.getDomaineContactList({
        domaine: {
          idDomaine: this.idDomaineSelectedFlow,
        },
        contact: {
          idContact: this.authService.user.contact[0].id,
        }
      } as IDomaineContactServiceInputs).then((contactDomaines) => {
        if (contactDomaines) {
          const domaine = contactDomaines[0];
          if (domaine.role === 'admin') {
            if (this.displayedColumns[0] !== 'select') {
              this.displayedColumns.unshift('select');
            }
          } else {
            this.displayedColumns = this.displayedColumns.filter(c => c !== 'select');
          }
        }
      });
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

  async getMonitoringCount(inputs: IMonitoringServiceInputs) {
    return this.monitoringCountService.getMonitoringCount(inputs).then((result) => {
      this.monitoringCount = result;
      this.updatePaginationCount();
    });
  }

  async getMonitoringStateList(inputs: IMonitoringStateServiceInputs): Promise<IMonitoringState[]> {
    const loadindElement = this.loaderService.addLoadingElement();
    inputs.orderBy = [{
      field: 'etatMonitoring.dateCreation',
      direction: 'ASC'
    }];
    return this.monitoringStateService.getMonitoringStateList(inputs).then((results: IMonitoringState[]) => {
      this.loaderService.removeLoadingElement(loadindElement);
      return results;
    });
  }

  async getDomaineContactList(inputs: IDomaineContactServiceInputs): Promise<IContactDomaine[]> {
    const loadindElement = this.loaderService.addLoadingElement();
    return this.domaineContactService.getDomaineContactList(inputs).then((results: IContactDomaine[]) => {
      this.loaderService.removeLoadingElement(loadindElement);
      return results;
    });
  }

  setPagination(totalPage: string) {
    this.pagination = {
      currentPage: '1',
      totalPage: '1',
      taillePage: totalPage,
      firstRow: '1',
      lastRow: Math.floor(+totalPage).toString(),
      totalRows: '0'
    };
    if (this.search) {
      this.updatePaginationCount();
    }
  }

  async updatePaginationCount() {
    if (this.monitoringCount && this.pagination) {
      this.pagination.totalRows = this.monitoringCount;
      this.pagination.totalPage = Math.ceil(+this.monitoringCount / +this.pagination.taillePage).toString();
      if (+this.pagination.currentPage > +this.pagination.totalPage) {
        this.pagination.currentPage = this.pagination.totalPage;
      }
      this.updatePaginationRows();
    }
  }

  updatePaginationRows() {
    if (this.pagination) {
      this.pagination.firstRow = Math.floor(
        (this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0) * +this.pagination.taillePage + 1
      ).toString();
      this.pagination.lastRow = Math.floor((+this.pagination.currentPage) * +this.pagination.taillePage).toString();
      if (+this.pagination.lastRow > +this.monitoringCount) {
        this.pagination.lastRow = this.monitoringCount;
      }
    }
  }

  OnGoMonitoring(monitoring: IMonitoring) {
    this.router.navigate([
      '/monitoring',
      monitoring.halfFlow.completeFlow.id,
      monitoring.instanceMonitoring.uuidInstanceMonitoring
    ]);
  }

  onGoPage(page: string) {
    if (!isNaN(Number(page))) {
      this.pagination.currentPage = page ? page : '1';
    } else {
      this.pagination.currentPage = '1';
    }
    if (Number(this.pagination.currentPage) < 0) {
      this.pagination.currentPage = '1';
    }
    if (Number(this.pagination.currentPage) > Number(this.pagination.totalPage)) {
      this.pagination.currentPage = this.pagination.totalPage;
    }
    this.paginateData();
  }

  onGoNext() {
    if (+this.pagination.currentPage < +this.pagination.totalPage) {
      this.pagination.currentPage = Math.floor(+this.pagination.currentPage + 1).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow + +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow + +this.pagination.taillePage).toString();
      this.paginateData();
    }
  }

  onGoPrevious() {
    if (+this.pagination.currentPage > 1) {
      this.pagination.currentPage = Math.floor(+this.pagination.currentPage - 1).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow - +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow - +this.pagination.taillePage).toString();
      this.paginateData();
    }
  }

  onGoFirst() {
    this.pagination.currentPage = '1';
    this.updatePaginationRows();
    this.paginateData();
  }

  onGoLast() {
    this.pagination.currentPage = this.pagination.totalPage;
    this.updatePaginationRows();
    this.paginateData();
  }

  paginateData() {
    if (!this.search.completeFlowId) {
      return;
    }
    const inputs = {
      monitoring: {
        dateCreation: {
          dateDebut: this.search.startDate,
          dateFin: this.search.endDate
        }
      },
      fluxComplet: {
        idFluxComplet: this.search.completeFlowId
      },
      application: {
        idApplication: this.search.applicationId
      },
      lastEtatMonitoring: {
        idEtat: this.search.stateId
      }
    } as IMonitoringServiceInputs;
    this.search.businessDatas.map(searchBusinessData => {
      const businessDataMonitoring = {
        businessDataMonitoring: {
          cle: searchBusinessData.key,
          valeur: searchBusinessData.value
        }
      } as IBusinessDataMonitoringListIn;
      inputs.businessDataMonitoringList.push(businessDataMonitoring);
    });
    this.getMonitoringList(inputs).then(() => {
      this.tabUpdate();
    });
    this.getMonitoringCount(inputs);
  }

  paginationTaillePageChanged() {
    this.pagination.currentPage = '1';
    this.updatePaginationCount().then(() => {
      this.paginateData();
    });
  }

  resubmit() {
    if (!this.selection.selected.length) {
      return;
    }
    const uuidMonitoringList = this.selection.selected.map(row => {
      return { uuidMonitoring: row.uuidMonitoring };
    });
    const loadindElement = this.loaderService.addLoadingElement();
    this.monitoringStateResubmitService.monitoringStateResubmit(uuidMonitoringList)
      .then(() => {
        this.loaderService.removeLoadingElement(loadindElement);
        this.openResubmitModal(null);
      }).catch((err) => {
        this.loaderService.removeLoadingElement(loadindElement);
        this.openResubmitModal(err.error.errorDetail);
      });
  }

  openResubmitModal(message: string) {
    this.dialog.open(FlowTrackingResubmitDialogComponent, {
      width: 'auto',
      autoFocus: false,
      data: {
        monitorings: this.selection.selected,
        message
      }
    }).afterClosed().subscribe(() => {
      this.selection.clear();
      this.paginateData();
    });
  }

  ////////////// CheckBox \\\\\\\\\\\\\\\\\\\\
  autoCheck() {
    if (this.selection.selected && this.selection.selected.length > 0) {
      this.selection.selected.forEach((element, index) => {
        const find = this.monitorings.find(m => m.id === element.id);
        if (find) {
          this.selection.deselect(element);
          if (find.lastMonitoringState.state.name === 'ERREUR') {
            this.selection.select(find);
          }
        }
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.lastMonitoringState.state.name === 'ERREUR').length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.filter(row => row.lastMonitoringState.state.name === 'ERREUR')
        .forEach(row => this.selection.select(row));
  }

  isMasterEnable(): boolean {
    const numRows = this.dataSource.data.filter(row => row.lastMonitoringState.state.name === 'ERREUR').length;
    return numRows === 0;
  }
  ////////////// CheckBox \\\\\\\\\\\\\\\\\\\\

  sortData(sort: Sort) {
    this.orderBy = [
      {
        direction: sort.direction,
        field: sort.active
      }
    ];
    this.onGoFirst();
  }
}
