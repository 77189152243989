<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="cle" matSortDirection="asc" matSortDisableClear>

  <ng-container matColumnDef="cle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Clé </th>
    <td mat-cell *matCellDef="let element"> {{element.key}} </td>
  </ng-container>

  <ng-container matColumnDef="valeur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valeur </th>
    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
