import { IApplication } from './../../../shared/models/application';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { DateService } from 'src/app/services/utils/date.service';
import { IState } from 'src/app/shared/models/state';
import { StateService, IStateServiceInputs } from 'src/app/services/API/cockpit/state.service';
import { IBusinessData } from 'src/app/shared/models/business-data';
import { BusinessDataService, IBusinessDataServiceInputs } from 'src/app/services/API/cockpit/business-data.service';
import { HalfFlowService, IHalfFlowServiceInputs } from 'src/app/services/API/cockpit/halfFlow.service';
import { IHalfFlow } from 'src/app/shared/models/half-flow';
import { DateAdapter } from '@angular/material/core';

interface ISearchBusinessData {
  key: string;
  value: string;
  application?: IApplication;
}
export interface ISearch {
  completeFlowId: string;
  stateId: string;
  applicationId: string;
  startDate: string;
  endDate: string;
  businessDatas: ISearchBusinessData[];
}

@Component({
  selector: 'app-flow-tracking-filter-bar',
  templateUrl: './flow-tracking-filter-bar.component.html',
  styleUrls: ['./flow-tracking-filter-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(
        ':enter',
        [
          style({
            overflow: 'hidden',
            height: 0
          }),
          animate(
            '400ms ease-in-out',
            style({
              overflow: 'hidden',
              height: '*'
            })
          )
        ]
      ),
      transition(
        ':leave',
        [
          style({
            overflow: 'hidden',
            height: '*'
          }),
          animate(
            '400ms ease-in-out',
            style({
              overflow: 'hidden',
              height: 0
            })
          )
        ]
      ),
      transition('in => out', animate('1s ease-in-out')),
      transition('out => in', animate('1s ease-in-out'))
    ])
  ]
})
export class FlowTrackingFilterBarComponent implements OnInit {
  @Input() id: string;
  @Input() state: string;
  @Input() completeFlows: ICompleteFlow[];
  @Output() resetEvent = new EventEmitter<null>();
  @Output() searchEvent = new EventEmitter<ISearch>();
  states: IState[];
  halfFlows: IHalfFlow[];
  businessDatas: IBusinessData[];
  search: ISearch;
  expended = false;
  nullState: IState = {
    color: null,
    icon: null,
    id: '0',
    label: 'Tous',
    name: 'null',
    niveauDeCriticite: null,
  };

  constructor(
    private dateService: DateService,
    private stateService: StateService,
    private businessDataService: BusinessDataService,
    private halfFlowService: HalfFlowService,
    private dateAdapter: DateAdapter<any>
  ) { }

  ngOnInit(): void {
    this.dateAdapter.setLocale('fr');
    this.getStateList(null);
    this.resetForm(false);
    if (this.id) {
      this.search.completeFlowId = this.id;
      this.selectedFlow(this.id);
    }
    if (this.state) {
      this.search.stateId = this.state;
    }
  }

  onSubmit() {
    const search: ISearch = {
      completeFlowId: this.search.completeFlowId,
      stateId: this.search.stateId === '0' ? null : this.search.stateId,
      applicationId: this.search.applicationId === '0' ? null : this.search.applicationId,
      startDate: this.dateService.formatDateTimeString(this.search.startDate),
      endDate: this.dateService.formatDateTimeString(this.search.endDate),
      businessDatas: []
    };
    this.search.businessDatas.map(searchBusinessData => {
      if (searchBusinessData.value !== null && searchBusinessData.value !== '') {
        search.businessDatas.push(searchBusinessData);
      }
    });
    this.searchEvent.emit(search);
  }

  resetForm(emit: boolean) {
    delete this.halfFlows;
    delete this.businessDatas;
    this.search = {
      completeFlowId: null,
      stateId: '0',
      applicationId: '0',
      startDate: null,
      endDate: null,
      businessDatas: []
    };
    if (emit) {
      this.resetEvent.emit();
    }
  }

  selectedFlow(completeFlowId: string) {
    this.getBuisinessDataList({
      fluxComplet: {
        idFluxComplet: completeFlowId
      }
    } as IBusinessDataServiceInputs).then(results => {
      this.search.businessDatas = [];
      results.map(businessData => {
        const searchBusinessData = {
          key: businessData.key,
          value: null,
          application: businessData.halfFlow.application,
        } as ISearchBusinessData;
        this.search.businessDatas.push(searchBusinessData);
      });
    });
    this.getHalfFlowList({
      fluxComplet: {
        idFluxComplet: completeFlowId
      }
    } as IHalfFlowServiceInputs);
  }

  getStateList(inputs: IStateServiceInputs) {
    this.stateService.getStateList(inputs).then((results: IState[]) => {
      this.states = [];
      this.states.push(this.nullState);
      this.states = this.states.concat(results);
    });
  }

  getHalfFlowList(inputs: IHalfFlowServiceInputs) {
    this.halfFlowService.getHalfFlowList(inputs).then((results: IHalfFlow[]) => {
      this.halfFlows = results;
    });
  }

  async getBuisinessDataList(inputs: IBusinessDataServiceInputs) {
    inputs.orderBy = [{
      field: 'application.nom',
      direction: 'asc'
    },
    {

      field: 'businessData.cle',
      direction: 'asc'
    }];
    return this.businessDataService.getBusinessDataList(inputs).then((results: IBusinessData[]) => {
      this.businessDatas = results;
      return results;
    });
  }

}
