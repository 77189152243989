import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardFilterBarComponent } from './dashboard-filter-bar/dashboard-filter-bar.component';
import { DashboardIndicatorsComponent } from './dashboard-indicators/dashboard-indicators.component';
import { DashboardIndicatorCardComponent } from './dashboard-indicator-card/dashboard-indicator-card.component';
import { DashboardFlowsTabComponent } from './dashboard-flows-tab/dashboard-flows-tab.component';



@NgModule({
  declarations: [
    DashboardComponent,
    DashboardFilterBarComponent,
    DashboardIndicatorsComponent,
    DashboardIndicatorCardComponent,
    DashboardFlowsTabComponent
  ],
  imports: [
    SharedModule,
    DashboardRoutingModule
  ]
})
export class DashboardModule { }
