import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from 'src/app/shared/shared.module';
import { PublicModule } from 'src/app/public/public.module';
import { PrivateModule } from 'src/app/private/private.module';

import { NavbarComponent } from './components/navbar/navbar.component';
import { LoaderComponent } from './components/loader/loader.component';



@NgModule({
  declarations: [
    NavbarComponent,
    LoaderComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    PublicModule,
    PrivateModule
  ],
  exports: [
    NavbarComponent,
    LoaderComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }
}
