import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/shared/models/user';
import { LoaderService } from '../../loader.service';
import { ContactService, IContactServiceInputs } from './contact.service';
import { IContact } from 'src/app/shared/models/contact';

export interface IAuthServiceInputs {
  username: string;
  password: string;
}
interface IOutputOut {
  areCredentialsValid: string;
  generatedToken: string;
}
interface IResults {
  output: IOutputOut;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: IUser;
  authState: Subject<boolean> = new Subject<boolean>();
  authStateObs: Observable<boolean> = this.authState.asObservable();
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService,
    private contactService: ContactService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  updateAuthState() {
    this.authState.next(this.user ? true : false);
  }

  async setUser(username: string, token: string) {
    this.user = {
      username,
      token,
      contact: null,
    };
    this.getDomaineContactList({
      contact: {
        loginLdap: username
      }
    } as IContactServiceInputs);
    this.updateAuthState();
  }

  getDomaineContactList(inputs: IContactServiceInputs): void {
    const loadindElement = this.loaderService.addLoadingElement();
    this.contactService.getDomaineContactList(inputs).then((results: IContact[]) => {
      this.user.contact = results;
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

  async authenticateUser(inputs: IAuthServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/authenticateUser';
    return this.http.post(url, inputs).toPromise().then((results: IResults) => {
      if (results.output.areCredentialsValid === 'true') {
        this.user = {
          username: inputs.username,
          token: results.output.generatedToken,
          contact: null
        };
        this.getDomaineContactList({
          contact: {
            loginLdap: inputs.username
          }
        } as IContactServiceInputs);
        this.updateAuthState();
      }
      return results.output.areCredentialsValid;
    }).catch((results: IResults) => {
      this.updateAuthState();
      return results.errorDetail;
    });
  }

  logoutUser() {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    delete this.user;
    this.updateAuthState();
    this.loaderService.clearLoadingElement();
    this.router.navigateByUrl('/login');
  }

}
