import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IDomaineCommon } from './models/common';
import { IOrderBy, IFluxCompletIn, IEtatInstanceFluxCompletListIn } from './models/in';
import { IFluxCompletOut } from './models/out';
import { ICompleteFlow } from 'src/app/shared/models/complete-flow';
import { MappingService } from './utils/mapping.service';
import { IEtatInstanceFluxComplet } from 'src/app/shared/models/etat-instance-flux-comple';

export interface ICompleteFlowServiceInputs {
  fluxComplet: IFluxCompletIn;
  domaine: IDomaineCommon;
  etatInstanceFluxCompletList: IEtatInstanceFluxCompletListIn[];
  orderBy: IOrderBy[];
}
interface ICompleteFlowListOutputs {
  fluxCompletList: IFluxCompletOut[];
  errorDetail: string;
}
interface ICompleteFlowCountOutputs {
  count: string;
  errorDetail: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompleteFlowService {
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private mapping: MappingService
  ) {
    this.baseUrl = environment.settings.protocole + environment.settings.url + ':' + environment.settings.port + '/restv2';
  }

  async getCompleteFlowList(inputs: ICompleteFlowServiceInputs): Promise<ICompleteFlow[]> {
    const url = this.baseUrl + '/apiCockpit/fluxComplet';
    const completeFlowList: ICompleteFlow[] = [];
    return this.http.post(url, inputs).pipe(
      map((outputs: ICompleteFlowListOutputs) => {
        outputs.fluxCompletList.map((output: IFluxCompletOut) => {
          const completeFlow: ICompleteFlow = this.mapping.mapCompleteFlow(output);
          completeFlowList.push(completeFlow);
        });
      })
    ).toPromise().then(() => {
      return completeFlowList;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  async getCompleteFlowCount(inputs: ICompleteFlowServiceInputs): Promise<string> {
    const url = this.baseUrl + '/apiCockpit/fluxCompletCount';
    return this.http.post(url, inputs).toPromise().then((outputs: ICompleteFlowCountOutputs) => {
      return outputs.count;
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }
}
