import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MonitoringRoutingModule } from './monitoring-routing.module';

import { MonitoringComponent } from './monitoring/monitoring.component';
import { MonitoringFilterBarComponent } from './monitoring-filter-bar/monitoring-filter-bar.component';
import { MonitoringInstancesTabComponent } from './monitoring-instances-tab/monitoring-instances-tab.component';
import { MonitoringDialogComponent } from './monitoring-dialog/monitoring-dialog.component';



@NgModule({
  declarations: [
    MonitoringComponent,
    MonitoringFilterBarComponent,
    MonitoringInstancesTabComponent,
    MonitoringDialogComponent
  ],
  imports: [
    SharedModule,
    MonitoringRoutingModule
  ]
})
export class MonitoringModule { }
