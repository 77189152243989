<form name="form" (ngSubmit)="onSubmit()">
  <div fxLayout="row" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="start center">
    <mat-form-field fxFlex="20">
      <mat-select placeholder="Flux" required #nomFlux="ngModel" name="nomFlux" [(ngModel)]="search.completeFlowId"
        (selectionChange)="selectedFlow($event.value)">
        <mat-option *ngFor="let completeFlow of completeFlows" [value]="completeFlow.id">
          {{ completeFlow.name }}
        </mat-option>
      </mat-select>
      <mat-error>Flux requis</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="20">
      <mat-select placeholder="Statut" #status="ngModel" name="status" [(ngModel)]="search.stateId">
        <mat-option *ngFor="let state of states" [value]="state.id">
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="20">
      <mat-select placeholder="Application" #apllication="ngModel" name="application" [(ngModel)]="search.applicationId"
        [disabled]="!search.completeFlowId">
        <mat-option [value]="'0'"> Tous</mat-option>
        <mat-option *ngFor="let halfFlow of halfFlows" [value]="halfFlow.application.id">
          {{ halfFlow.application.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayoutGap="5%" fxLayout="row">
      <div class="form-group-button">
        <button type="button" mat-raised-button class="btn btn-primary" (click)="resetForm(true)">Réinitialiser</button>
      </div>
      <div class="form-group-button">
        <button mat-raised-button color="search" class="btn btn-secondary">Rechercher</button>
      </div>
    </div>
  </div>

  <div *ngIf="!expended" fxLayout="row" fxLayoutAlign="center">
    <span fxLayout="row" (click)="expended=true" style="cursor: pointer;">
      <mat-icon>expand_more</mat-icon> Plus <mat-icon>expand_more</mat-icon>
    </span>
  </div>
  <div *ngIf="expended" fxLayout="row" fxLayoutAlign="center">
    <span fxLayout="row" (click)="expended=false" style="cursor: pointer;">
      <mat-icon>expand_less</mat-icon> Moins <mat-icon>expand_less</mat-icon>
    </span>
  </div>

  <div *ngIf="expended" [@slideInOut] fxLayout="row wrap" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="start center">
    <mat-form-field class="date-input">
      <input matInput placeholder="Date de début" type="datetime-local" step="1"  [(ngModel)]="search.startDate" name="startDate">
      <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="search.startDate = null" type="button">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="date-input">
      <input matInput placeholder="Date de fin" type="datetime-local" step="1"  [(ngModel)]="search.endDate" name="endDate">
      <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="search.endDate = null" type="button">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div *ngIf="expended" [@slideInOut] fxLayout="row wrap" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="start center">
    <mat-form-field *ngFor="let searchBusinessData of search.businessDatas">
      <input matInput placeholder="{{searchBusinessData.key  + ' (' + searchBusinessData.application.name + ')'}}" name="{{searchBusinessData.key}}"
        [(ngModel)]="searchBusinessData.value">
    </mat-form-field>
  </div>
</form>
