import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { IPagination } from 'src/app/shared/models/pagination';
import { IState } from 'src/app/shared/models/state';
import { IOrderBy } from 'src/app/services/API/cockpit/models/in';
import { MonitoringDialogComponent } from '../monitoring-dialog/monitoring-dialog.component';
import { MonitoringService, IMonitoringServiceInputs } from 'src/app/services/API/cockpit/monitoring.service';
import { MonitoringCountService } from 'src/app/services/API/cockpit/monitoring-count.service';
import { IMonitoring } from 'src/app/shared/models/monitoring';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-monitoring-instances-tab',
  templateUrl: './monitoring-instances-tab.component.html',
  styleUrls: ['./monitoring-instances-tab.component.scss']
})
export class MonitoringInstancesTabComponent implements OnInit, OnChanges {
  @Input() selectedCompleteFlowId: string;
  @Input() selectedInstanceMonitoringId: any;
  @Input() selectedState: IState;
  monitoring: IMonitoring[];
  monitoringCount: string;
  displayedColumns: string[] = ['application.nom', 'lastEtatMonitoring.dateCreation', 'lastEtatMonitoring.niveauDeCriticiteEtat', 'bussinessData'];
  dataSource: MatTableDataSource<IMonitoring>;
  pagination: IPagination;
  orderBy: IOrderBy[] = [{
    field: 'lastEtatMonitoring.dateCreation',
    direction: 'DESC'
  }];

  constructor(
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private monitoringService: MonitoringService,

    private monitoringCountService: MonitoringCountService,
  ) { }

  ngOnInit(): void {
    this.setPagination('50');
    this.tabUpdate();
  }

  ngOnChanges(): void {
    this.searchData();
  }

  private searchData(): void {
    if (this.pagination === undefined) {
      this.setPagination('50');
    }
    this.pagination.currentPage = '1';
    if (this.selectedCompleteFlowId) {
      const inputs = {
        fluxComplet: {
          idFluxComplet: this.selectedCompleteFlowId !== 'ALL' ? this.selectedCompleteFlowId : null,
        },
        lastEtatMonitoring: {
          idEtat: this.selectedState ? this.selectedState.id : null
        },
        instanceMonitoring: {
          uuidInstanceMonitoring: this.selectedInstanceMonitoringId
        }
      } as IMonitoringServiceInputs;
      this.getMonitoringList({ ...inputs }).then(() => {
        this.tabUpdate();
      });
      this.getMonitoringCount(inputs);
    }
  }

  async getMonitoringList(inputs: IMonitoringServiceInputs) {
    const loadindElement = this.loaderService.addLoadingElement();
    inputs.orderBy = this.orderBy;
    inputs.pagination = {
      offset: this.pagination.taillePage,
      startIndex: (+this.pagination.taillePage * (this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0)).toString()
    };
    return this.monitoringService.getMonitoringList(inputs).then((results: IMonitoring[]) => {
      this.monitoring = results;
      this.loaderService.removeLoadingElement(loadindElement);
    });
  }

  tabUpdate() {
    this.dataSource = new MatTableDataSource<IMonitoring>(this.monitoring);
  }

  openDialog(uuidMonitoring: string) {
    const dialogRef = this.dialog.open(MonitoringDialogComponent, {
      width: 'auto',
      minWidth: '50%',
      maxHeight: '90vh',
      autoFocus: false,
      data: uuidMonitoring,
    });
    dialogRef.afterClosed();
  }

  async getMonitoringCount(inputs: IMonitoringServiceInputs) {
    return this.monitoringCountService.getMonitoringCount(inputs).then((result) => {
      this.monitoringCount = result;
      this.updatePaginationCount();
    });
  }

  setPagination(totalPage: string) {
    this.pagination = {
      currentPage: '1',
      totalPage: '1',
      taillePage: totalPage,
      firstRow: '1',
      lastRow: Math.floor(+totalPage).toString(),
      totalRows: '0'
    };
    if (this.selectedCompleteFlowId) {
      this.updatePaginationCount();
    }
  }

  async updatePaginationCount() {
    if (this.selectedCompleteFlowId && this.pagination) {
      this.pagination.totalRows = this.monitoringCount;
      this.pagination.totalPage = Math.ceil(+this.monitoringCount / +this.pagination.taillePage).toString();
      if (+this.pagination.currentPage > +this.pagination.totalPage) {
        this.pagination.currentPage = this.pagination.totalPage;
      }
      this.updatePaginationRows();
    }
  }

  updatePaginationRows() {
    if (this.pagination) {
      this.pagination.firstRow = Math.floor(
        (this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0) * +this.pagination.taillePage + 1
      ).toString();
      this.pagination.lastRow = Math.floor((+this.pagination.currentPage) * +this.pagination.taillePage).toString();
      if (+this.pagination.lastRow > +this.monitoringCount) {
        this.pagination.lastRow = this.monitoringCount;
      }
    }
  }

  onGoPage(page: string) {
    if (!isNaN(Number(page))) {
      this.pagination.currentPage = page ? page : '1';
    } else {
      this.pagination.currentPage = '1';
    }
    if (Number(this.pagination.currentPage) < 0) {
      this.pagination.currentPage = '1';
    }
    if (Number(this.pagination.currentPage) > Number(this.pagination.totalPage)) {
      this.pagination.currentPage = this.pagination.totalPage;
    }
    this.paginateData();
  }

  onGoNext() {
    if (+this.pagination.currentPage < +this.pagination.totalPage) {
      this.pagination.currentPage = Math.floor(+this.pagination.currentPage + 1).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow + +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow + +this.pagination.taillePage).toString();
      this.paginateData();
    }
  }

  onGoPrevious() {
    if (+this.pagination.currentPage > 1) {
      this.pagination.currentPage = Math.floor(this.pagination.currentPage !== '0' ? +this.pagination.currentPage - 1 : 0).toString();
      this.pagination.firstRow = Math.floor(+this.pagination.firstRow - +this.pagination.taillePage).toString();
      this.pagination.lastRow = Math.floor(+this.pagination.lastRow - +this.pagination.taillePage).toString();
      this.paginateData();
    }
  }

  onGoFirst() {
    this.pagination.currentPage = '1';
    this.updatePaginationRows();
    this.paginateData();
  }

  onGoLast() {
    this.pagination.currentPage = this.pagination.totalPage;
    this.updatePaginationRows();
    this.paginateData();
  }

  paginateData() {
    if (!this.selectedCompleteFlowId) {
      return;
    }
    const inputs = {
      fluxComplet: {
        idFluxComplet: this.selectedCompleteFlowId !== 'ALL' ? this.selectedCompleteFlowId : null,
      },
      lastEtatMonitoring: {
        idEtat: this.selectedState ? this.selectedState.id : null
      },
      instanceMonitoring: {
        uuidInstanceMonitoring: this.selectedInstanceMonitoringId
      }
    } as IMonitoringServiceInputs;
    this.getMonitoringList({ ...inputs }).then(() => {
      this.tabUpdate();
    });
    this.getMonitoringCount(inputs);
  }

  paginationTaillePageChanged() {
    this.pagination.currentPage = '1';
    this.updatePaginationCount().then(() => {
      this.paginateData();
    });
  }

  sortData(sort: Sort): void {
    this.orderBy = [{
      direction: sort.direction.toUpperCase(),
      field: sort.active
    }];

    this.onGoFirst();
  }
}
