<mat-card fxLayout="row" [ngStyle]="{'color': fontColor, 'background': bgColor, 'border': borderColor}">
  <div fxLayoutAlign="start center" fxFlex="60">
    <div fxLayout="column" class="content">
      <div class="count">{{ count }}</div>
      <div>{{ label }}</div>
    </div>
  </div>
  <div fxLayoutAlign="space-evenly center" fxFlex="40">
    <mat-icon aria-hidden="false">{{ icon }}</mat-icon>
  </div>
</mat-card>
