import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/API/cockpit/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isAuthenticated: boolean;

  constructor(
    private authService: AuthService
  ) {
    this.authService.authStateObs.subscribe((authState) => {
      this.isAuthenticated = authState;
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logoutUser();
  }

}
